
section{
    padding-top: 7vh;
}

.templateContainer{
    padding: 30px 15vw;
    padding-right: 9vw;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 90px;
    
}
.templateContainer1{
    padding: 30px 0px;
    padding-left: 0vw;
    padding-right: 9vw;
    display: grid;
    grid-template-columns: 0.4fr 1.8fr 0.7fr;
    gap: 50px;
    position: relative;
}
.farLeftSide{
    // position: fixed;
    // width: 120px;
    // height: 100vh;
    // top: 80px;
    // background-color: red;
    font-size: 12px;
    padding-left: 5vw;
    
}
.menuContainer{
    position: sticky;
    top: 150px;
    div{
        padding: 7px 0px;
        // opacity: 0.5;
        color: rgb(198, 196, 196);
        &:hover{
           color: rgb(35, 35, 35);
            cursor: pointer;
        }
    }
}
.menuItem{
    font-weight: bold;
    color: rgb(35, 35, 35) !important;
}
.iconsDiv{
    display: flex;
    align-items: center;
    font-size: 25px;
    :nth-child(1){
        margin-right: 20px;
        &:hover{
            cursor: pointer;
            color: #0049ff;
        }
    }
    :nth-child(2){
        &:hover{
            cursor: pointer;
            color: #0049ff;
        }
    }
    
}
.headerContainer{
    padding: 0px 15vw;
    padding-top: 30px;   
    display: flex;
    justify-content: space-between;
    
}
.headerTitle{
    font-size: 50px;
    font-weight: bold;
    padding-left: 15px;
    color: black;
}
.headerSubTitle{
    padding: 0px 15vw;
    font-size: 20px;
}
.badgeContainer{
    display: flex;
    // justify-content: space-between;
    padding: 0px 15vw;
    padding-top: 30px;   
    div{
        margin-right: 10px;
        padding: 5px 6px;
        border-radius: 3px;
        font-size: 12px;
        background-color: rgb(238, 238, 238);
        border-color: rgb(238, 238, 238);
        &:hover{
            background-color: rgb(217, 216, 216);
            border-color: rgb(217, 216, 216);
            font-weight: 500;
            cursor: pointer;
        }
    }
}
.slideContainer{
    width: 100%;
    // min-height: 505px;
}
.slideThumbnails{
    display: flex;
    border: 3px solid transparent;

}
.thumb{

}
.selectedThumb{

    border: 3px solid #0049ff;
    padding: 5px;
}

.badgeSpecial{
        margin-right: 10px;
        padding: 5px 6px;
        border-radius: 3px;
        font-size: 12px;
        border: 1px solid #F83A5C;
        color: #F83A5C;
        display: flex;
        align-items: center;
        &:hover{
            // background-color: rgb(217, 216, 216);
            border-color: #F83A5C;
            font-weight: 500;
            cursor: pointer;
        }
}
.badge{
    margin-right: 10px;
    padding: 5px 6px;
    border-radius: 3px;
    font-size: 12px;
    background-color: rgb(238, 238, 238);
    border-color: rgb(238, 238, 238);
    &:hover{
        background-color: rgb(217, 216, 216);
        border-color: rgb(217, 216, 216);
        font-weight: 500;
        cursor: pointer;
    }
}



//Right Side

.totalAmount{
    color: #19c157;
    margin-bottom: 4px;
    font-size: 35px;
    font-weight: 900;
    letter-spacing: .01em;
}
.totalAmountSub{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .01em;
    color: #5f6368;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
   
}
.sectionContainer{
    padding-top: 20px;
    padding-bottom: 0px;
}
.title{
    font-size: 35px;
    font-weight: 800;
    color: black;
    display: flex;
    align-items: center;
}
.subtitle{
    font-size: 18px;
    font-weight: 400;
    color: #5f6368;
    // padding-bottom: 10px;
}

.investButton{
    background-color: rgb(0, 73, 255);
    width: 100%;
    padding: 12px 25px;
    font-size: 16px;
    border-radius: 6px;
    color: white;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}


// Tab styles

.stickyHeader{
    position: sticky;
    top: 100px;
    background-color: white;
    z-index: 1;
    padding-top: 10px;
//   .isStuck{
//     background-color: red;
//     .tabInvestButton{
//         margin-right: 9vw;
//         background-color: rgb(0, 73, 255);
//         width: 250px;
//         // height: 30px;
//         padding: 12px 30px;
//         font-size: 17px;
//         border-radius: 6px;
//         color: white;
//         font-weight: 600;
//         text-align: center;
//     }
//   }
}
.tabInvestButton{
    // margin-right: 15vw;
    background-color: rgb(0, 73, 255);
    width: 350px;
    // height: 30px;
    padding: 12px 30px;
    font-size: 17px;
    border-radius: 6px;
    color: white;
    font-weight: 600;
    text-align: center;
}
.stickyHeader .isStuck{
    background-color: red;
    
}
.tabsContainer{
  
    // gap: 50px;
    display: flex;
    // padding-left: 15vw;
}
.tabs{
    padding-right: 50px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(179, 179, 179);
    cursor: pointer;
    display: flex;
    align-items: center;
}
.selectedTab{
    margin-right: 50px;
    font-size: 18px;
    border-spacing: 20px;
    text-underline-offset: 20px;
   
    // border-bottom: 3px solid rgb(0, 73, 255);
    font-weight: 600;
   
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgb(0, 73, 255);
    :nth-child(1){
        text-decoration: underline;
        text-decoration-thickness: 4px;
       
    }
}
.numberBadge{
    // display: inline-block;
    // margin-left: 2px;
    // padding: 5px;
    // background-color: rgb(238, 238, 238);
    // font-size: 16px;
    // line-height: 1.1em;
    // font-weight: 400;
    // color: rgb(106, 106, 106);
    // border-radius: 3px;
    display: inline-block;
    margin-left: 5px;
    padding: 1px 6px 3px;
    background-color: rgb(238, 238, 238);
    font-size: 16px;
    line-height: 1.1em;
    font-weight: 400;
    color: rgb(106, 106, 106);
    border-radius: 3px;

}


.sectionTitle{
    font-size: 25px;
    font-weight: 800;
    color: rgb(102, 102, 102);
    // padding-bottom: 10px;
}
.smallhr{
    width: 56px;
    margin: 24px 0px;
    border: 1px solid rgb(238, 238, 238);
    // color: rgb(102, 102, 102);
}
.vipCardContainer{
    display: flex;

}
.vipCard{
    margin-right: 20px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    min-height: 104px;
    padding: 16px 60px 16px 24px;
    background-color: rgb(238, 210, 155);
    box-shadow: rgb(0 0 0 / 6%) 0px 4px 16px;
    border-radius: 5px;
    overflow: hidden;
    outline: none;
    cursor: default;
    transition: background-color 0.1s ease-in-out 0s;
}
.vipCardContent{
    width: 160px;
    :nth-child(1){
        font-size: 18px;
        line-height: 1.2em;
        font-weight: 500;
        letter-spacing: -0.015em;
        color: rgb(51, 51, 51);
    }
    :nth-child(2){
        padding-top: 10px;
        font-size: 12px;
        line-height: 1.4em;
        font-weight: 400;
        letter-spacing: -0.015em;
        color: rgb(51, 51, 51);
    }
}
.vipCardIcon{
    // width: 100px;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 50px;
    background-color: rgb(245, 217, 163);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: opacity 0.1s ease-in-out 0s, background-color;
    img{
        max-width: 30px;
        max-height: 34px;
        filter: invert(72%) sepia(15%) saturate(1241%) hue-rotate(355deg) brightness(114%) contrast(84%);
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;

    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 9999999;
        top:-20px;
        // bottom: 150%;
        left: 50%;
        margin-left: -60px;
        &::after{
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent transparent;
        }
      }
      &:hover{
        .tooltiptext {
            visibility: visible;
        }
      }
  }
  

.bulletPoints{
    padding-top: 20px;
    li{
        word-break: break-word;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 12px;
        line-height: 1.6;
        color: black;
    }
}
  
.contentText{
    margin-bottom: 30px;
    line-height: 1.6;
    font-size: 20px;
    color: black;
    
}  
.termWrapper{
    border-bottom: 1px solid rgb(238, 238, 238);
    // padding-bottom: 20px;
}
.termItem{
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    font-size: 18px;
}
.termDesc{
    font-size: 11px;
    font-weight: 400;
    color: #5f6368;
    padding-bottom: 10px;
    line-height: 1.6;

}
.learnMore{
    font-size: 11px;
    font-weight: 400;
    color: blue;
    // padding-bottom: 10px;
    line-height: 1.6;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
}
.howItWorksBtn{
    margin-top: 30px;
    width: 100%;
    border: 1px solid lightgray;
    color: black;
    // font-size: 18px;
    font-weight: 600;
    padding: 10px 24px;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    &:hover{
        color:  rgb(0, 73, 255);
        border: 1px solid rgb(0, 73, 255);
        transition: all 0.2s ease-in-out 0s;
        cursor: pointer;
    }
}

.docWrapper{
    border: 1px solid #eee;
    // padding: 16px;
}
.docDesc{
    padding: 16px;
}
.secLink{
    display: flex;
    align-items: center;
  
    padding: 16px 16px;
    border-bottom: 1px solid #eee;
    &:hover{
        cursor: pointer;
        background-color: #eee;
    }
}
.docContainer{
    padding: 16px 0px;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.investCard{
    padding: 16px;
    border-bottom: 1px solid #eee;
    &:hover{
        cursor: pointer;
        background-color: #fbfbfb;
    }
}

.investLable{
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 16px;
    color: #666;
}
.amountStyle{
    font-weight: 400;
    color: #0049ff;
    line-height: 1;
    font-size: 35px;
    padding-bottom: 30px;
}
.benefitList{
   padding-left: 16px;
   li{
    font-size: 15px;
    margin-top: 4px;
    line-height: 1.4;
   }
}

.modalStyle{
    padding: 20px;
}
.tableStyle{
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
}
.selectedMenu{
    border-bottom: 2px solid #0049ff;
    color: #0049ff;
    font-weight: 600;
}

.mobileFarLeftSide{
    display: none;
}




.bottomStyle{
// padding: 0px 7vw;
    // display: grid;
    // grid-template-columns: 0.2fr 1fr;
   
}
.bottomContent{
    // padding: 20px;
    // border-top: 1px solid #eee;
}
.aboutCompany{
    padding: 10px 0px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #eee;

}
.bottomTitle{
    font-size: 25px;
    font-weight: 900;
    // padding-bottom: 16px;
    // padding: 40px 0px;
    padding-top: 40px;
    padding-bottom: 10px;
}
.blueBar{
    border: 2px solid #0049ff;
    width: 10%;
    text-align: center;
    margin-bottom: 60px;
}
.aboutContent{
    display: grid;
    grid-template-columns: 0.6fr 0.6fr 1fr;
    // padding-right: 10vw;
    margin-bottom: 60px;
    // :nth-child(1){
    //     background-color: red;
    // }
    // :nth-child(2){
    //     background-color: green;
    // }
    // :nth-child(3){
    //     background-color: blue;
    // }
}
.labelName{
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
    color: #777;
}   
.labelData{
    font-size: 18px;
    // font-weight: 500;
    padding-bottom: 10px;
    color: black;
    padding-bottom: 30px;
}

.teamWrapper{
    display:flex;
    // grid-template-columns: 1fr 1fr 1fr;
    // flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .teamItem{
        width: 33%;
        // background-color: red;
        margin-bottom: 20px;
        text-align: center;
        padding: 30px;
    }
}

.faqContainer{
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    gap: 30px;
}


// Mobile Version


@media only screen and (max-width: 767px) {
    .headerContainer{
        padding: 20px 20px;
    }
    .headerSubTitle{
        font-size: 16px;
        padding: 0px 20px;
    }
    .iconsDiv{
        display: none;
    }
    
    .headerTitle{
        font-size: 24px;
    }
    .badgeContainer{
        padding: 0px 20px;
        padding-top: 30px;   
        div{
            margin-right: 10px;
            padding: 5px 6px;
            border-radius: 3px;
            font-size: 10px;
            background-color: rgb(238, 238, 238);
            border-color: rgb(238, 238, 238);
            &:hover{
                background-color: rgb(217, 216, 216);
                border-color: rgb(217, 216, 216);
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
    .templateContainer{
        // padding: 30px 15vw;
        // // padding-left: 15vw;
        // display: grid;
        // grid-template-columns: 2.5fr 1fr;
        // gap: 90px;
        display: flex;
        flex-direction: column;
        padding: 20px 20px;
        gap: 20px;
    }
    .slideContainer{
        height: 100%;
        :nth-child(1){
            height: 200px !important;
            border-radius: 8px;
        }
    }
    .totalAmount{
        font-size: 24px;
        // padding: 0px 20px;
    }
    .totalAmountSub{
        font-size: 16px;
        // padding: 0px 20px;
    }
    .statsMobile{
        display: flex;
        justify-content: space-between;
        .sectionContainer{
            padding: 10px 0px;
        }
        hr{
            // width: 100%;
            margin: 0px 20px;
        }
    }
    .stickyHeader{
        display: none;
    }
    .templateContainer1{
        display: flex;
        flex-direction: column;
        padding: 0px 0px;
        .farLeftSide{
            display: none;
        }
    }
    .mobileFarLeftSide{
        background-color: white;
        padding: 0px 20px;
        position: sticky;
        top: 0;
        z-index: 999;
        display: flex;
        justify-content: space-between;
        // padding: 20px 20px;
        // .farLeftSide{
        //     display: none;
        // }
        border: 1px solid #eee;
        border-width: 1px 0px 1px 0px;
        padding: 20px 0px;
        select{
            border: none;
            width: 60%;
            font-size: 20px;
            // border-right: 1px solid #eee;
            padding:0px 20px
        }
    }
    .leftSide{
        padding: 20px;
    }
    .rightSide{
        padding: 20px;
    }
    .iconsDivMobile{
        display: flex;
        align-items: center;
        font-size: 30px;
        padding-right: 20px;
        border-left: 1px solid #eee;
        padding-left: 20px;
        :nth-child(1){
            margin-right: 20px;
            &:hover{
                cursor: pointer;
                color: #0049ff;
            }
        }
        :nth-child(2){
            &:hover{
                cursor: pointer;
                color: #0049ff;
            }
        }
    }
    .vipCard{
        width: 100%;
        margin-bottom: 20px;
    }
    .vipCardContent{
        width: 100%;
        padding: 20px;
        :nth-child(1){
            font-size: 16px;
        }
        :nth-child(2){
            padding-top: 10px;
            font-size: 12px;
        }
    }
    .vipCardIcon{
        width: 100%;
        height: 100px;
        background-size: 100%;
        background-position: 50% 50%;
        img{
            max-width: 50px;
            max-height: 50px;
        }
    }
    .contentText{
        margin-bottom: 20px;
        font-size: 16px;
    }
    .termItem{
        flex-direction: column;
        padding: 10px 0px;
    }
    .termDesc{
        padding-bottom: 0px;
    }
    .howItWorksBtn{
        margin-top: 20px;
    }
    .docContainer{
        padding: 10px 0px;
        font-size: 14px;
    }
    .investCard{
        padding: 10px;
    }
    .investLable{
        font-size: 14px;
    }
    .amountStyle{
        font-size: 24px;
        padding-bottom: 20px;
    }
    .benefitList{
        li{
            margin-top: 2px;
        }
    }
    .modalStyle{
        padding: 10px;
    }
    .tableStyle{
        padding: 6px 0px;
    }
}
.questionItem{
    font-size: 16px;
    padding: 20px 0px;
    cursor: pointer;
    &:hover{
        color: #0049ff;
    }
}
.answerWrapper{
    font-size: 14px;
    padding: 10px 0px;
    border: 1px solid #eee;
    height: fit-content;
    padding: 16px 24px;
}
.answerHeader{
    font-size: 16px;
    padding: 10px 0px;
    font-weight: 600;
}
.answerText{
    font-size: 14px;
    padding: 10px 0px;
    line-height: 2;
}

.riskAnswerWrapper{
    background-color: #eee;
    font-size: 14px;
    padding: 10px 0px;
    border: 1px solid #eee;
    height: fit-content;
    padding: 16px 24px;
}