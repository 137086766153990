.imNavbar {
  // border: 1px solid red;
  height: 76px;
  display: flex;
  justify-content: space-between;
  padding: 0 6.5vw;
  min-height: 76px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
  }

  & > .leftNav {
    // border: 1px solid red;
    min-width: 30px;
    cursor: pointer;
    font-weight: 650;
    max-width: 15%;

    & > a {
      & > img {
        height: 100%;
        object-fit: contain;
        max-width: 100%;
      }
    }
  }

  & > .rightNav {
    display: flex;
    gap: 2.5rem;

    & > a {
      text-decoration: none;
      cursor: pointer;
      transition: all ease 0.1s;

      &:hover {
        border-bottom: 1px solid #4caf50;
      }
    }
  }
}
