.wholeNavbar {
  position: fixed;
  top: 36px;
  left: 0;
  right: 0;
  color: #5f6163;
  z-index: 2;
}

#topNavbar {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 36px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border: 0.5px solid #e5e5e5;
  border-top: none;
  border-left: none;
  cursor: pointer;
}

#topNavbar > div {
  min-width: 70px;
  width: 8vw !important;

  display: flex;
}

#topNavbar > div > div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#topNavbar > div > div > img {
  width: 17px;
  height: 17px;
}

#mainNavbar {
  z-index: 3;
  width: 100%;
  height: 67px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-top: 0px;
  display: flex;
  position: relative;
}

#topNavbar > div > div {
  border-right: 0.5px solid #e5e5e5;
}

#topNavbar > div > div:hover {
  background: rgba(24, 25, 29, 0.1);
}

#mainNavbar-left {
  width: 40%;
  height: 100%;
  display: flex;
  min-width: 300px;
  border-bottom: 0.5px solid #e5e5e5;
}

#mainNavbar-left > :first-child {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(10vw / 1.5) !important;
  margin-right: calc(10vw / 2.5) !important;
}

#mainNavbar-left > :first-child > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

#mainNavbar-left > :last-child {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border-right: 0.5px solid #e5e5e5; */
  /* border-left: 0.5px solid #e5e5e5; */
}

.topNav {
  position: absolute;
  display: flex;
  margin-left: auto;
  right: 0;
  border-right: 0px;
  min-width: 70px;
  width: 8vw !important;
  height: 100%;
  /* background-color: #283790; */
  background: #4caf50;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 1s;
  font-weight: 700;
}

.topNavlanding {
  font-weight: 500;
  color: #5f6163;
}

.topNavlandingDefault {
  color: #5f6163;
}

.topNav:hover {
  cursor: pointer;
  font-size: calc(13px + 0.2vw);
  opacity: 0.8;
}

.topNav > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topNav > div > div:hover {
  cursor: pointer;
}

.topNavLogin {
  right: 8vw;
  background: #ec207c;
}

#mainNavSearch {
  max-width: 150px;
  width: 85%;
  height: 30px;

  opacity: 0.25;

  outline: none;
  border: none;
}

#mainNavbarLogo {
  width: 15vw;
  transition: 1s;
  /* max-width: 155px; */
  max-width: 300px;
}

#mainNavbarLogo:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.topNav-first {
  width: calc(100% - 950px);
  height: 100%;
}

.mainNavSearch {
  cursor: text;
  min-width: 150px;
}

#mainNavbar-right {
  display: flex;
  width: 65%;
  position: relative;
  font-size: calc(8px + 0.5vw);
  font-style: normal;
  font-weight: 400;
  line-height: 1vh;
  position: relative;
  margin-right: 6.5vw;
}

#mainNavbar-right-last {
  display: grid;
  height: 100%;
  width: 87%;
  position: absolute;
  grid-auto-flow: column;
  grid-template-columns: repeat(1fr);
  max-width: 90%;
  right: 10vw !important;
  cursor: pointer;
  left: 0;
  border-bottom: 0.5px solid #e5e5e5;
  border-left: 0.5px solid #e5e5e5;
}

#mainNavbar-right-last > a > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.5px solid #e5e5e5;
  transition: 0.5s;
  width: 100%;
  height: 100%;
  position: relative;
}

#mainNavbar-right-last > a > div::after {
  content: '';
  position: absolute;
  bottom: 25%;
  height: 2px;
  width: 0%;
  background-color: #4caf50;
  transition: all ease 0.4s;
}

#mainNavbar-right-last > a .mainNavActive::after {
  width: 25%;
}

.mainNavItemsScrolled {
  border: none !important;
}

#mainNavbar-right-last > a:hover {
  font-size: calc(10px + 0.4vw);
  font-weight: 600;
  /* background-color: rgba(24, 25, 29, .1); */
}

#mainNavMenu {
  display: none;
  z-index: 2;
}

.topNavScrolled {
  position: absolute;
  margin-left: auto;
  inset: 15px;
  border-right: 0;
  min-width: 70px;
  width: 8vw !important;
  background: #4caf50;
  color: #fff;
  justify-content: center;
  cursor: pointer;
  transition: 1s;
  font-weight: 700;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 840px) {
  .topNav {
    transform: translate(-12px, -49%) !important;
    width: 111px !important;
    max-width: 138px;
    height: 38px !important;
  }
  #mainNavbar-right-last {
    display: none;
  }

  #mainNavbar {
    height: 80px;
  }

  #topNavbar {
    display: none;
  }

  .wholeNavbar {
    top: 0;
  }

  #mainNavbar-left {
    width: 100%;
  }

  /* #mainNavbar-right {
    display: none;
  } */

  #mainNavSearch {
    display: none;
  }

  #mainNavbarLogo {
    width: 60%;
    max-width: 400px;
  }

  /* #mainNavbar-right {
    display: none;
  } */

  .mainNavSearch {
    display: none;
  }

  #mainNavbar-left > :first-child {
    position: relative;
    width: 100%;
  }

  #mainNavbar-left > :last-child {
    display: none;
    width: 0;
  }

  #mainNavMenu {
    position: absolute;
    display: block;
    display: flex;
    margin: 0 20px;
    justify-content: center;
    align-items: center;
    top: 50%;
    bottom: 50%;
  }
}
