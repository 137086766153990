.mapContainer {
  height: calc(100vh - 163px);
  // border-top: 0.5px solid #e5e5e5;
  //   padding: 0 4vw;
  //   padding-right: 8vw;
  //   border: 1px solid red;
  padding: 3%;
  background: linear-gradient(
    90.43deg,
    #4caf50 3.17%,
    #4caf50 3.18%,
    #93d996 105.2%
  );
padding-left: 6.5vw;
padding-right: 6.5vw;
  & > .mainContainer {
    display: flex;
    // border: 1px solid green;
    height: 100%;
    // padding: 0 4vw;
    // padding-right: 8vw;
    background-color: white;
    border-radius: 25px;

    & > .contentDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1%;
      width: 50%;
      padding: 2rem 0;
      padding-left: 5vw;
      //   align-items: center;

      & > .logoDiv {
        height: 4.2rem;

        & > img {
          height: 100%;
          max-width: 100%;
          object-fit: contain;
        }
      }

      & > .title {
        font-size: 6.5rem;
        font-weight: 800;
        line-height: 1.2;
        color: #4caf50;
      }

      & > .para {
        font-size: 1.3rem;
        font-weight: 400;
        color: #5f6163;
        width: 60%;
      }

      & > .btnDiv {
        height: 4rem;
        display: flex;
        gap: 1rem;
        margin-top: 1.5%;

        & > :nth-child(1) {
          background: #ffffff;
          opacity: 0.9;
          border: 1px solid #e5e5e5;
          border-radius: 5px;
          color: #5f6163;
        }
        & > :nth-child(2) {
          background: #4caf50;
          opacity: 0.9;
          border: 0.5px solid #e7e7e7;
          border-radius: 5px;
          color: white;
        }

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem 3.5rem;
          //   transition: all ease-in 0.4s;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: 700;

          &:hover {
            // border: none;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }
        }
      }

      & > .countdown {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // height: 17.5vh;
        gap: 20px;

        & > .eachContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          & > .countValue {
            font-size: 4.5rem;
            font-weight: 800;
            color: #4caf50;
            line-height: 1.2;
          }
          & > .countName {
            font-size: 1rem;
            font-weight: 600;
            color: #5f6163;
          }
        }

        & > :last-child {
          display: none;
        }
      }
    }

    & > .imageDiv {
      width: 50%;
      display: flex;
      // justify-content: center;
      align-items: center;
      gap: 2rem;
      overflow-x: scroll;
      // padding: 2rem 0;
      // padding-right: 5vw;

      & > div {
        position: relative;
        height: 90%;
        width: fit-content;
        // flex-grow: 1;
        // border: 1px solid red;
        // width: -webkit-fill-available;
        display: flex;
        justify-content: center;

        & > img {
          min-height: 100%;
          // width: 100%;
          // max-width: 100%;
          object-fit: contain;

          // &::after {
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          //   content: 'Startups';
          //   color: white;
          //   font-weight: 700;
          //   font-size: 1.5rem;
          //   width: 2rem;
          //   height: 2rem;
          //   border: 1px solid red;
          // }
        }

        & > .content {
          position: absolute;
          top: 92.5%;
          left: 40%;
          transform: translate(-50%, -50%);
          color: white;
          font-weight: 800;
          font-size: 2.5rem;
        }
      }

      & > :nth-child(2) {
        & > img {
          border-radius: 10px;
        }
      }
    }
  }
}
