.productDropdown {
  position: fixed;
  left: 35%;
  top: 103px;
  width: 55vh;
  min-height: 50vh;
  padding: 2.5%;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > .title {
    font-size: 1.2rem;
    color: #5f6163;
    font-weight: 600;
  }

  & > .itemsContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;

    gap: 2.2rem;
    overflow-y: scroll;
  }
}

.productItem {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all ease-in 0.4s;

  & > :nth-child(1) {
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    background: linear-gradient(
      270.43deg,
      #4caf50 3.17%,
      #4caf50 3.18%,
      #93d996 105.2%
    );
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 60%;
      object-fit: contain;
    }
  }

  & > :nth-child(2) {
    & > :nth-child(1) {
      font-size: 1.2rem;
      font-weight: 700;
    }

    & > :nth-child(2) {
      font-size: 1rem;
    }
  }

  &:hover {
    transform: translateX(10px);
    border-radius: 5px;
    padding: 0 2%;
    background: linear-gradient(
      180deg,
      rgba(76, 175, 80, 0.1) 0%,
      rgba(147, 217, 150, 0.1) 100%
    );
  }
}

.directoryDropdown {
  position: fixed;
  left: 42.5%;
  top: 103px;
  width: 55vh;
  min-height: 50vh;
  padding: 2.5%;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > .title {
    font-size: 1.2rem;
    color: #5f6163;
    font-weight: 600;
  }

  & > .itemsContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
  }
}

.directoryItem {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all ease-in 0.4s;

  & > :nth-child(1) {
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    background: #e5e5e5;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 60%;
      object-fit: contain;
    }
  }

  & > :nth-child(2) {
    & > :nth-child(1) {
      font-size: 1.2rem;
      font-weight: 700;
    }

    & > :nth-child(2) {
      font-size: 1rem;
    }
  }

  &:hover {
    transform: translateX(10px);
    border-radius: 5px;
    padding: 0 2%;
    background: linear-gradient(
      180deg,
      rgba(76, 175, 80, 0.1) 0%,
      rgba(147, 217, 150, 0.1) 100%
    );
  }
}

.marketverseDropdown {
  position: fixed;
  left: 22.5%;
  top: 103px;
  min-width: 60%;
  min-height: 50vh;
  max-height: calc(90vh - 103px);
  padding: 3%;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > .seperateContainer {
    display: flex;
    justify-content: space-between;

    & > div {
      overflow-y: scroll;
      & > .totalContainer {
        & > .title {
          font-size: 1.2rem;
          color: #5f6163;
          font-weight: 600;
        }
        & > .itemsContainer {
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          gap: 2.2rem;
          margin-top: 1.5rem;
        }
      }
    }

    & > :nth-child(1) {
      border-right: 1px solid #e7e7e7;
      padding-right: 2%;

      & > :nth-child(2) {
        margin-top: 2rem;
      }
    }

    & > :nth-child(2) {
      padding-left: 2%;
    }
  }
}

.marketverseItem {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all ease-in 0.4s;

  & > :nth-child(1) {
    min-width: 4rem;
    min-height: 4rem;
    max-width: 4rem;
    max-height: 4rem;
    // background: #e5e5e5;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 60%;
      object-fit: contain;
    }
  }

  & > :nth-child(2) {
    & > :nth-child(1) {
      font-size: 1.2rem;
      font-weight: 700;
    }

    & > :nth-child(2) {
      font-size: 1rem;
    }
  }

  &:hover {
    transform: translateX(10px);
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(76, 175, 80, 0.1) 0%,
      rgba(147, 217, 150, 0.1) 100%
    );
  }
}

.companyDropdown {
  position: fixed;
  right: 5%;
  top: 103px;
  width: 55vh;
  min-height: 50vh;
  padding: 2.5%;
  background: #ffffff;
  border: 0.5px solid #e7e7e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & > .title {
    font-size: 1.2rem;
    color: #5f6163;
    font-weight: 600;
  }

  & > .itemsContainer {
    // border: 1px solid red;
    display: flex;
    flex-direction: column;

    gap: 2.2rem;
    overflow-y: scroll;
  }
}
