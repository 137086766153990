.educationContainer {
  //   border: 1px solid red;
  width: 100%;
  height: calc(100% - 103px);
  margin-top: 103px;
  // border: 1px solid red;
  display: flex;

  & > .leftContainer {
    width: 45%;
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    padding: 4rem 2rem;
    gap: 1.5rem;
    overflow-y: scroll;
    background: #f5f5f5e1;

    & > div {
      max-width: 550px;
      width: 100%;
    }

    & > :nth-child(1) {
      display: flex;
      justify-content: space-between;
      font-size: 1.8rem;
      font-weight: 800;
      color: #7d7d7de6;

      & > img {
        width: 1.8rem;
        height: 1.8rem;
        cursor: pointer;
      }
    }

    & > .selectionBtns {
      display: flex;
      gap: 2rem;

      & > div {
        width: 40%;
        max-width: 150px;
        padding: 0.7rem 2.2rem;
        font-size: 1rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      & > :nth-child(2) {
        opacity: 0.35;
        pointer-events: none;
        background: white;
      }

      & > .selectedBtn {
        background: #4caf50;
        border-radius: 10px;
        color: white;
      }

      & > .notselectedBtn {
        background: #ffffff;
        border: 0.5px solid #ffffff;
        border-radius: 10px;
      }
    }

    & > :nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 2rem;
    }
  }

  & > .rightContainer {
    width: 55%;
    // border: 1px solid green;
  }
}

.eachVideoContainer {
  // width: 100%;
  //   height: 30%;
  //   min-height: 15vw;
  //   min-height: fit-content;
  display: flex;
  // justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 0.8rem 0.5rem;
  background-color: white;
  border: 0.5px solid #e5e5e5;
  transition: all ease-in-out 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px rgb(24 25 29 / 30%);
  }

  & > :nth-child(1) {
    min-width: 30%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;

    & > img {
      min-height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }

  & > .contentDiv {
    // width: 70%;
    // border: 1px solid green;
    height: 100%;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    & > :nth-child(1) {
      font-size: 1.4rem;
      font-weight: 600;
    }

    & > :nth-child(2) {
      font: 0.8rem;
      font-weight: 500;
      line-height: 1.7;
    }
  }
}

.exclusiveDiv {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  // border: 1px solid green;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow-y: scroll;

  & > .generalCard {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    // border: 1px solid red;

    > iframe {
      display: block;
      width: 100%;
      border: 0;
      min-height: 18vw;
    }

    & > :nth-child(1) {
      width: 100%;
      height: 30%;
      min-height: 20vw;
      background: rgba(255, 255, 255, 0.1);
      border: 0.5px solid #e5e5e5;
      border-radius: 10px;
    }

    & > .contentDiv {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      line-height: 2;

      & > :nth-child(1) {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;
      }

      & > :nth-child(2) {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  & > .ceoCard {
    // display: flex;
    display: none;
    padding: 1.5rem;
    background: rgba(237, 237, 237, 0.37);
    border-radius: 15px;
    max-width: 500px;
    gap: 1.5rem;

    & > :nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        height: 100%;
      }
    }

    & > .detailsCard {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      & > :nth-child(1) {
        font-size: 1.5rem;
        font-weight: 700;
      }

      & > :nth-child(2) {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
}
