.retireContentContainer {
  width: 60%;
  height: 100%;
  //   border: 1px solid blue;
  padding: 3rem 2rem;
  overflow: scroll;

  > .logoContainer {
    height: 6rem;

    > img {
      height: 100%;
    }
  }

  > .contentContainer {
    // padding: 0.5rem;
    width: 100%;
    margin-top: 3rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.5;
    padding: 0.5rem 1.5rem;

    > .inputContainer {
      //   height: 3rem;
      max-width: 600px;
      color: #7d7d7d50;
      font-size: 1.2rem;
      font-weight: 500;
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-bottom: 3rem;

      > input {
        outline: none;
        border: 0;
        padding: 1rem 2rem;
        width: 88%;
      }
      > img {
        height: 1.5rem;
        max-height: 1.5rem;
        padding-right: 2rem;
        cursor: pointer;
      }
    }
  }

  .eachContentContainer {
    margin-top: 4.5rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;

    .eachContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      > iframe {
        display: block;
        min-height: 25vw;
        border: 0;
      }

      > .videoDiv {
        width: 100%;
        height: 20vw;
        border: 0.5px solid #e7e7e7;
        border-radius: 10px;
      }

      > .contentDiv {
        display: flex;
        flex-direction: column;
        line-height: 1.8;
        gap: 1rem;

        > :nth-child(1) {
          display: flex;
          align-items: center;
          gap: 1rem;

          > .title {
            font-size: 1.5rem;
            font-weight: 700;
          }

          > img {
            height: 3.5rem;
            width: 3.5rem;
          }
        }
        > .contentContainer {
          //   font-size: 1rem;
          color: #7d7d7de6;
        }
      }
    }
  }
}

.retireVideosContainer {
  width: 40%;
  height: 100%;
  padding: 3rem 2rem;
  border-left: 0.5px solid #e7e7e7;

  > .videoTitle {
    font-size: 3rem;
    font-weight: 700;
    color: #7d7d7d;
  }

  .iframesWrapper {
    width: 100%;
    height: 90%;
    margin-top: 3.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;

    > iframe {
      display: block;
      outline: none;
      border: 0;
      min-height: 17vw;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      // align-items: center;
      justify-content: center;

      > img {
        width: 100%;
        height: 30%;
        min-height: 21vw;
        cursor: pointer;
      }

      > .playlistTitle {
        font-size: 1.7rem;
        font-weight: 600;
      }
    }
  }

  // > iframes {
  //   width: 100%;
  //   height: 30vh;
  // }
}
