.productBonds {
    height: calc(100vh - 108px);
    width: 100%;
    margin-top: 108px;
    /* overflow-y: hidden; */
}

.bondsPageFirst {
    width: 85vw;
    max-width: 85vw;
    height: 70%;
    margin: auto;
    background: white;
    display: flex;
    padding-top: 30px;
    justify-content: center;
    align-items: center;
}

.bondsPageFirst>:first-child {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

.bondsPageFirst>:last-child {
    width: 40%;
    height: 100%;
    /* background: url("../../assets/images/products/bondsmain.jpg"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 100%;
}

.bondsPageFirst>:last-child>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translateY(3.5%);
}

.bondsPageFirst>:first-child>div {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bondsHeading {
    font-style: normal;
    font-weight: 800;
    font-size: calc(20px + 2vw);
    display: flex;
    align-items: center;
    color: #5f6163;
}

.bondsPara {
    font-style: normal;
    font-size: calc(10px + 0.4vw);
    line-height: 2;
    display: flex;
    align-items: center;
    color: #5f6163;
}

.bondsBtns {
    width: 70%;
    height: 30%;
    display: flex;
    gap: 15px;
}

.bondsBtns>div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 0.5px solid #EBEBEB;
    border-radius: 10px;
    min-height: 50px;
    max-height: 60px;
    width: 10vw;
    min-width: 190px;
    max-width: 240px;
    transition: all .5s;
    cursor: pointer;
}

.bondsBtns>div>img {
    object-fit: contain;
    max-height: 25px;
    max-width: 105px;
    min-height: 25px;
    min-width: 105px;
}

.bondsPageSecond {
    min-height: 30%;
    width: 100%;
    background: #f5f5f5;
    display: flex;
}

.bondsContentContainer {
    width: 85vw;
    max-width: 85vw;
    height: max-content;
    padding: 25px 0;
    margin: auto;
}

.bondsQues {
    font-style: normal;
    font-weight: 800;
    font-size: calc(10px + 1vw);
    display: flex;
    align-items: center;
    color: #5f6163;
}

.bondsSearch {
    height: 50%;
    width: 100%;
    padding-top: 22px;
    display: flex;
    gap: 30px;
    font-weight: 800;
    font-size: calc(10px + 0.6vw);
}

.bondsSearch>* {
    border-radius: 30px;
    background: white;
    padding: 0 20px;
}

.bondsSearch>select {
    padding-right: 40px;
    background: white;
    border: none;
}

.bondsSearch input {
    /* background: white; */
    border: none;
    outline: none;
    color: rgba(21, 23, 42, 1);
    font-weight: 600;
    padding: 0px 40px;
    min-height: 70px;
}

.bondsSearch input::placeholder {
    color: rgba(21, 23, 42, 0.15);
    ;
}

.bondsSearch select {
    font-weight: 700;
    color: #464B4E;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='34' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 45%;
}

.bondsSearch>:first-child {
    width: 55%;
}

.bondsSearch>:nth-child(2) {
    width: 25%;
}

.bondsSearch>:last-child {
    cursor: pointer;
    width: 15%;
    background: #4CAF50;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightSideMenu {
    height: 100%;
    width: 40%;
    min-width: 480px;
    max-width: 60vh;
    position: fixed;
    right: 0;
    top: 103px;
    bottom: 0;
    z-index: 2;
    /* transform: translateX(100vw); */
    transition: all ease 0.5s;
}

.rightSideMenu>:first-child {
    height: 25vh;
    position: relative;
    background: white;
    /* border-bottom: 1px solid white; */
}

.rightSideMenu>:first-child>:nth-child(1) {
    background-color: #4CAF50;
    height: 15vh;
}

.rightSideMenu>:first-child>:nth-child(2) {
    background-color: white;
    height: 10vh;
}

.rightSideMenu>:last-child {
    padding: 0 4vh;
    /* padding-top: 15vh; */
    background: white;
    height: calc(90vh - 209px);
    /* height: calc(85vh - 104.5px); */
    overflow-y: scroll;

}

.rightSideMenuInput {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.rightSideMenuInput>div {
    display: flex;
    justify-content: space-between;
}

.rsb-bold {
    font-size: calc(15px + 0.8vh);
    font-weight: 800;
}

.bondSideValues {
    font-weight: 800;
    font-size: calc(10px + 0.4vw);
}

.bondSideDetails {
    padding-top: 5vh;
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.bondSideDetails>div {
    padding: 3vh;
    font-weight: 800;
    font-size: calc(10px + 0.6vw);
    min-height: 50px;
    width: 100%;
    height: 20%;
    border: 0.5px solid #E7E7E7;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightSideOverlapContainer {
    height: 15vh;
    width: 80%;
    display: flex;
    margin: auto;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.rightSideOverlapContainer>div>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
}

.rightSideOverlapContainer>:first-child {
    width: 30%;
    border-radius: 20px;
    /* padding-right: 25px; */
    /* min-height: 13vh;
    max-height: 130px; */
    background: white;
    border: 0.5px solid #E5E5E5;
    border-radius: 10px;
    padding: 20px;
}

.rightSideOverlapContainer>:nth-child(2) {
    width: 30%;
    border: 0.5px solid #E5E5E5;
    border-radius: 30px;
    height: 35%;
    background-color: white;
    padding: 15px 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;
    cursor: pointer;
}

.rightSideOverlapContainer>:nth-child(3) {
    width: 30%;
    background-color: white;
    border: 0.5px solid #E5E5E5;
    border-radius: 30px;
    height: 35%;
    padding: 15px 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;
    cursor: pointer;
}

.overlayactive {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
}


@media screen and (max-width:1500px) {
    .bondsSearch>* {
        min-height: 50px;
    }
}

@media screen and (max-width:1600px) {

    .rsb-bold {
        font-size: 15px;
    }

    .bondsPara {
        font-size: 11px;
    }

    .bondSideValues {
        font-size: 11px;
    }

    .bondSideDetails>div {
        font-size: 13px;
    }

    .rightSideOverlapContainer>:nth-child(2),
    .rightSideOverlapContainer>:nth-child(3) {
        font-size: 10px;
    }
}

@media screen and (max-width:1000px) {
    .bondsPageFirst>:last-child {
        display: none;
    }

    .bondsPageFirst>:first-child {
        width: 100%;
    }
}

@media screen and (max-width:850px) {
    .bondsBtns {
        flex-direction: column;
    }

    .productBonds {
        height: calc(100vh - 80px);
        margin-top: 80px;
        /* overflow-y: scroll; */
    }

    .bondsPageFirst {
        padding-top: 0;
    }

    .bondsPageFirst>:first-child {
        padding-bottom: 0;
    }

    .bondsSearch {
        flex-direction: column;
    }

    .bondsPageSecond {
        height: max-content;
    }

    .bondsSearch>:nth-child(2) {
        width: 40%;
    }

    .bondsSearch>:nth-child(3) {
        width: 25%;
    }
}

@media screen and (max-width:550px) {
    .bondsPageFirst {
        height: max-content;
        padding: 20px 0;
    }

    .bondsSearch>* {
        min-height: 40px;
    }

    .bondsSearch input {
        min-height: 40px;
    }
}