.landingDiv {
    /* height: calc(100vh - 80px); */
    width: 100vw;
    /* border: 1px solid red; */
    margin-top: 80px;
}

.introDiv {
    min-height: 40vh;
    height: max-content;
    width: 100%;
    background: #f9f9f9;
    padding: 10% 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.introDiv>div {
    max-width: 85vw;
}

.introDiv>div:first-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.introDiv>div:first-child>div:first-child {
    font-weight: 800;
    font-size: var(--section-font-size-mobileL1);
    line-height: 35px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.introDiv>div:first-child>div:last-child {
    font-weight: 700;
    font-size: var(--section-font-size-mobileS1);
    line-height: 29px;
    display: flex;
    align-items: center;

    color: #4CAF50;
}

.introDiv>div:last-child {
    display: flex;
    flex-direction: column;
    width: 85%;
    justify-content: space-between;
    gap: 15px;
}

.introDiv>div:last-child>div:first-child {
    font-size: var(--section-font-size-mobileN1);
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #445A74;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    height: 47px;
    width: 47vw;
    max-width: 47vw;
    font-weight: 700;
    background: white;
    padding: 10px;
}

.introDiv>div:last-child>div:last-child {
    font-weight: 700;
    font-size: var(--section-font-size-mobileN1);
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #445A74;
    background: white;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    height: 47px;
    min-width: 47vw;
    max-width: 47vw;
    padding: 10px;
}

/* middle section */

.middleSectionM {
    padding: 15px 6%;
    min-height: 40vh;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    white-space: nowrap;
}

.middleSectionM>div {
    padding: 10% 7%;
    display: flex;
    flex-direction: column;
}

.middleSectionM>div:first-child,
.middleSectionM>div:nth-child(3) {
    justify-content: center;
    align-items: flex-start;
}

.middleSectionM>div:last-child,
.middleSectionM>div:nth-child(2) {
    justify-content: center;
    align-items: flex-end;
}

.middleSectionM>div>div:first-child {
    font-weight: 700;
    font-size: 30px;
    line-height: 160%;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    color: #5F6163;
}

.middleSectionM>div>div:last-child {
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
    display: flex;
    align-items: center;
    letter-spacing: 0.002em;
    color: #5F6163;
}

/* greenSectionM */

.greenSectionM {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background: #4CAF50;
    border: 0.5px solid #4CAF50;
    padding: 10% 6%;
}

.greenSectionM>div {
    opacity: 0.75;
}


.miniNavbarM {
    /* border: 1px solid paleturquoise; */
    height: 45px;
    padding: 0px 6%;
    width: 100%;
    border-bottom: 0.5px solid #E5E5E5;
    background-color: white;
    position: sticky;
    top: 79px;
}

.miniNavbarM>ul {
    display: flex;
    /* justify-content: center; */
    height: 100%;
    align-items: center;
    overflow-x: scroll;
    gap: 25px;
    list-style-type: none;
    margin: 0;
}

.miniNavbarM>ul>li {
    font-size: 15px;
    font-weight: 400;
    color: #5F6163;
    /* padding: 0 5px; */
}

.miniSections {
    width: 100vw;
    /* height: calc(100vh - 45px - 80px); */
    /* border: 1px solid violet; */
}

.sectionContainerM {
    /* border: 1px solid goldenrod; */
    /* height: 100%; */
    padding: 15% 6%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 700px;
    margin: auto;
}

.sectionContainerLogoM>img {
    max-height: 35px;
}

.sectionContainerTitleM {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 175%;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.sectionContainerLineM {
    height: 1px;
    /* background: #F7931A;
    border: 1.5px solid #F7931A; */
}

.sectionContainerListM {
    width: 90%;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 267%;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin: 15px auto;
}

.sectionContainerCardsM {
    /* height: 250px; */
    max-width: 100%;
    min-height: 240px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow-x: hidden;
    /* scroll-snap-type: y mandatory; */
}

.sectionContainerSingleCardsM {
    max-width: 100%;
    height: 95px;
    min-height: 45%;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    display: flex;
    padding: 3% 5%;
    justify-content: space-between;
}

.sectionContainerSingleCardsM>div:first-child {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
}

.sectionContainerSingleCardsM>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.sectionContainerSingleCardsImgM {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
}

.sectionContainerSingleCardsImgM>img {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 50%;
}

.sectionContainerSingleCardsBoldTextM {
    font-style: normal;
    font-weight: 650;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.sectionContainerSingleCardsLightTextM {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.sectionContainerCardsIndividualM {
    width: 100%;
    height: 100%;
    /* scroll-snap-align: start; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
}

.nftImgDiv {
    overflow: scroll;
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 15px;
}

.nftImgDiv img {
    width: 100%;
    height: 100%;
    margin: auto;
}