.hidden {
  opacity: 0;
  transition: all ease 1s;
  filter: blur(5px);
  transform: translateX(-100%);
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.overall-div {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

.businesslandingPageContainer {
  position: relative;
  width: 100vw;
  height: calc(100vh - 103px);
  margin-top: 103px;
  display: flex;
}

.left-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-block {
  width: 70%;
  min-height: 70%;
  max-height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.fastest {
  color: #4caf50;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

.h1-title {
  color: #5f6163;
  font-weight: 700;
  font-size: 3.7rem;
  letter-spacing: normal;
  line-height: 1;
  margin-bottom: 1rem;
}

.list {
  color: #464154;
  font-weight: 400;
  font-size: 1.25rem;
}

.join {
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 0 10px 10px 10px;
  width: 30%;
  height: 4.5rem;
  margin-top: 1rem;
  transition: all ease 0.4s;
  font-weight: 700;
}

.join:hover {
  scale: 1.07;
}

.timeline-section {
  width: 100vw;
  height: calc(100vh - 103px);
  background: #f8faff;
  padding: 5rem 0;
}

.heading {
  font-size: 3rem;
  font-weight: 750;
  text-align: center;
}

.timeline-tabs {
  margin: 0 auto;
  padding: 3rem;
  display: flex;
  justify-content: center;
  gap: 5rem;
}

.tab1 {
  padding-bottom: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  opacity: 0.5;
  transition: all ease 0.4s;
}

.tab1:hover {
  scale: 1.07;
}

.timeline-slider {
  display: flex;
  overflow-x: scroll;
  height: 60%;
  width: 100%;
  padding-left: 5rem;
}

.cont1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.month-cont {
  display: flex;
  height: 20%;
  width: 100%;
  align-items: center;
}

.month {
  border-right: 1px dotted gray;
  width: 50%;
  display: flex;
  justify-content: center;
  color: gray;
  opacity: 0.5;
}

.detail-cont {
  width: 100%;
  height: 80%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 3rem;
  position: relative;
}

.detail {
  height: 4rem;
}

.detail p {
  font-size: 1.5rem;
  line-height: 0.5;
  font-weight: 600;
}

.color {
  color: gray;
  opacity: 0.5;
}

.btnn {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  height: 3rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0 1.5rem;
}

.timeline-section1 {
  width: 100vw;
  height: max-content;
  background: #ffffff;
  padding: 5rem 0;
}

.steps {
  width: 100%;
  min-height: calc(100vh - 103px);
  display: flex;
  height: 40%;
  padding: 5rem;
  justify-content: space-between;
  align-items: center;
}

.carousel-container {
  width: 37%;
  background: #fbf6c7;
  border-radius: 0 25px 25px 25px;
  height: 75vh;
}

.instructions {
  width: 50%;
  height: max-content;
}

.inst-heading {
  font-size: 2.5rem;
  font-weight: 750;
  color: #4caf50;
}

.sub-text {
  font-size: 1.25rem;
  line-height: 1.1;
}

.points {
  margin-left: 1rem;
}

.points li {
  font-size: 1.35rem;
  font-weight: 750;
  color: #25224a;
  margin-bottom: 1.5rem;
}

.testimonial-section {
  background: #eaf0ff;
  width: 100%;
  height: 85vh;
  padding: 3rem 5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.carousel-div {
  width: 70%;
  margin: 0 auto;
  height: 60%;
}

.ad-section {
  width: 100%;
  height: 150vh;
  padding: 7rem 5rem;
}

.external-img {
  margin: 5rem auto 1rem;
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.external-img1 {
  margin: 3rem auto 1rem;
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.join-div {
  background: #c8f5bc;
  margin: 10rem auto 5rem;
  width: 90%;
  height: 60vh;
  border-radius: 0 50px 50px 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}

.join-heading {
  color: #25224a;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 0.9;
}

.join-btn {
  color: white;
  background: #4caf50;
  border-radius: 0 10px 10px 10px;
  width: 15%;
  height: 4rem;
  margin-top: 1rem;
  transition: all ease 0.4s;
  border: none;
  font-weight: 700;
}

.join-btn:hover {
  scale: 1.07;
}

@media screen and (max-width: 450px) {
  .left-container {
    width: 100%;
    .fastest {
      margin-bottom: 1rem;
    }
    .h1-title {
      margin-bottom: 2.5rem;
    }
    .list {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
    .join {
      width: 100%;
      height: 5.5rem;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .timeline-tabs {
    padding: 1rem;
    gap: 2rem;
  }
  .timeline-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .heading {
      font-size: 2.5rem;
    }
    .tab1 {
      font-size: 1.4rem;
    }
  }
  .timeline-section1 {
    .heading {
      font-size: 2.5rem;
    }
    .steps {
      width: 100%;
      flex-direction: column;
      padding: 2rem 3rem;
      .carousel-container {
        width: 100%;
        height: 50vh;
      }
      .instructions {
        width: 100%;
        margin-top: 2rem;
        .sub-text {
          p {
            line-height: 1.75;
            font-size: 1.5rem;
          }
        }
        .points li {
          font-size: 1.75rem;
        }
      }
    }
  }
  .testimonial-section {
    height: 100vh;
    padding: 3rem 4rem;
    justify-content: center;
    .heading {
      font-size: 2.75rem;
      line-height: 1.2;
    }
    .carousel-div {
      height: 70%;
    }
  }
  .ad-section {
    height: max-content;
    padding: 7rem 3rem 3rem 3rem;
    .sub-text {
      font-size: 1.5rem;
      line-height: 1.5;
    }
    .external-img {
      width: 100%;
      margin: 3rem auto 0;
      img {
        width: 28%;
      }
    }
    .external-img1 {
      width: 50%;
      margin: 2rem auto;
      img {
        width: 40%;
      }
    }
    .join-div {
      width: 100%;
      gap: 2rem;
      margin: 5rem auto;
      .join-heading {
        line-height: 1.3;
        text-align: center;
        font-size: 2.8rem;
        width: 95%;
      }
      .join-btn {
        width: 60%;
        height: 5rem;
        margin-top: 0;
        font-size: 1.75rem;
        font-weight: 550;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .left-container {
    .h1-title {
      font-size: 3rem;
    }
  }
  .timeline-section {
    .tab1 {
      font-size: 1.25rem;
    }
  }
  .testimonial-section {
    height: 120vh;
  }
  .ad-section .join-div .join-heading {
    font-size: 2.5rem;
  }
}
