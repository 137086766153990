.alice-carousel {
  height: 100%;
}

.alice-carousel > :nth-child(1) {
  width: 100%;
  height: 100%;
}

.alice-carousel__wrapper {
  height: 100%;
}

.item {
  width: 100%;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.author {
  display: flex;
  gap: 1rem;
  width: 40%;
  align-items: center;
  justify-content: center;
}

.author > img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 1;
}

.name-author p {
  margin-bottom: 0;
}

.content-area {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .author {
    width: 100%;
    flex-direction: column;
  }
  .alice-carousel__wrapper {
    .alice-carousel__stage {
      li {
        width: 100%;
      }
    }
  }
}
