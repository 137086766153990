.chatComponent {
  width: 32%;
  flex: 0 0 32%;
  background: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $border-color;
  position: relative;
  animation: chatShow 800ms;
  .title {
    margin: 30px 40px 25px 40px;
    line-height: 1;
    font-weight: 700;
    font-size: 45px;
    color: $primary-color;
    position: relative;
    .sizeIcon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 30px;
      margin: auto;
    }
  }
  .poweredBy {
    display: flex;
    margin: 0;
    display: flex;
    align-items: baseline;
    font-size: 12px;
    color: white;
    background: $primary-color;
    font-weight: 500;
    padding: 8px 40px;
    img {
      margin-left: 7px;
      filter: brightness(0) invert(1);
      height: 14px;
    }
    .switchBtns {
      margin-left: auto;
    }
    .switch {
      margin-left: auto;
      display: flex;
      height: 25px;
      align-items: center;
      span {
        font-size: 12px;
        font-weight: 600;
      }
      .switchWrap {
        margin: 0 3px;
        height: 16px;
        width: 30px;
        border-radius: 8px;
        border: 2px solid white;
        background: none;
        padding: 2px;
        display: flex;
        .switchBall {
          background: white;
          height: 8px;
          width: 8px;
          border-radius: 5px;
          margin-left: 0;
          margin-right: auto;
          &.true {
            margin-left: auto;
            margin-right: 0;
          }
        }
      }
    }
  }
  .btnsScrlWrapper {
    height: 80px !important;
    border-bottom: 1px solid $border-color;
    .btnsScrlList {
      display: flex;
      padding: 20px 40px;
      &::after {
        content: '';
        height: 100%;
        flex: 0 0 35px;
      }
      .btnSwitch {
        cursor: pointer;
        border: 1px solid $border-color;
        flex: 0 0 28%;
        margin-right: 8%;
        font-size: 13px;
        display: flex;
        font-weight: 500;
        justify-content: center;
        align-items: center;
        &.true {
          border: 3px solid $border-color;
        }
      }
    }
    &.true {
      border-bottom: none;
    }
  }
  .actionBtns {
    padding: 10px 40px;
    .head {
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
      margin-bottom: 15px;
    }
    .botOptionWrapper {
      height: 100px !important;
      .botOptionList {
        margin: 5px 0;
        display: flex;
        .option {
          cursor: pointer;
          flex: 0 0 23%;
          margin-right: 2.66%;
          border: 1px solid $border-color;
          border-radius: 4px;
          height: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            height: 40%;
            width: 40%;
            object-fit: contain;
          }
          span {
            text-align: center;
            line-height: 1;
            font-size: 13px;
            margin-top: 4px;
          }
          &.true {
            border: 4px solid $border-color;
          }
          &.n-5 {
            flex: 0 0 17.8%;
          }
        }
      }
    }
  }
  .humanChatArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    .chatView {
      height: 0 !important;
      flex: 1;
      .question {
        margin: 0 3%;
        margin-top: 3%;
        display: flex;
        .bot {
          width: 70px;
          display: flex;
          flex-direction: column;
          .img-logo {
            margin: 0 auto;
            height: 50px;
            width: 50px;
            padding: 5px;
            background: $primary-color;
            border-radius: 25px;
          }
          p {
            text-align: center;
            font-size: 11px;
          }
        }
        .question-wrap {
          margin: auto auto auto 8px;
          padding: 12px;
          min-width: 20%;
          max-width: 60%;
          background: #e8eaef50;
          border-bottom-left-radius: 20px;
          border-top-right-radius: 20px;
          display: flex;
          flex-direction: column;
          .question-text {
            width: 100%;
            font-size: 14px;
            word-break: break-word;
            margin: 0;
          }
          .preview {
            width: 100%;
            height: fit-content;
            position: relative;
            svg {
              width: 100%;
              height: auto;
            }
            .previewItem {
              object-fit: contain;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              &.file {
                padding: 35px;
              }
            }
          }
        }
        .question-loading {
          width: 100px;
          height: 40px;
          margin-left: 16px;
          margin-right: auto;
          padding: 0 12px;
          display: flex;
          align-items: center;
          background: #e8eaef50;
          font-size: 14px;
          border-radius: 30px;
          .lds-ellipsis {
            display: inline-block;
            position: relative;
            width: 60px;
            height: 20px;
            div {
              position: absolute;
              top: 6px;
              width: 9px;
              height: 9px;
              border-radius: 50%;
              background: rgb(177, 177, 177);
              animation-timing-function: cubic-bezier(0, 1, 1, 0);

              &:nth-child(1) {
                left: 8px;
                animation: lds-ellipsis1 0.6s infinite;
              }

              &:nth-child(2) {
                left: 8px;
                animation: lds-ellipsis2 0.6s infinite;
              }

              &:nth-child(3) {
                left: 32px;
                animation: lds-ellipsis2 0.6s infinite;
              }

              &:nth-child(4) {
                left: 56px;
                animation: lds-ellipsis3 0.6s infinite;
              }
            }
          }

          @keyframes lds-ellipsis1 {
            0% {
              transform: scale(0);
            }

            100% {
              transform: scale(1);
            }
          }

          @keyframes lds-ellipsis3 {
            0% {
              transform: scale(1);
            }

            100% {
              transform: scale(0);
            }
          }

          @keyframes lds-ellipsis2 {
            0% {
              transform: translate(0, 0);
            }

            100% {
              transform: translate(24px, 0);
            }
          }
        }
      }
      .answer {
        margin: 0 3%;
        margin-top: 3%;
        display: flex;
        .bot {
          width: 70px;
          display: flex;
          flex-direction: column;
          .img-logo {
            margin: 0 auto;
            background-color: $primary-color;
            height: 50px;
            width: 50px;
            border-radius: 25px;
          }
          p {
            text-align: center;
            font-size: 11px;
          }
        }
        .question-wrap {
          margin: auto 8px auto auto;
          padding: 12px;
          min-width: 20%;
          max-width: 60%;
          height: fit-content;
          background: white;
          border: 1px solid $border-color;
          border-bottom-left-radius: 20px;
          border-top-right-radius: 20px;
          display: flex;
          flex-direction: column;
          .question-text {
            width: 100%;
            font-size: 14px;
            word-break: break-word;
            margin: 0;
          }
          .preview {
            width: 100%;
            height: fit-content;
            position: relative;
            svg {
              width: 100%;
              height: auto;
            }
            .previewItem {
              object-fit: contain;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              &.file {
                padding: 35px;
              }
            }
          }
        }
        .question-text {
          font-size: 14px;
          word-break: break-word;
        }
      }
    }
    .chatInputs {
      display: flex;
      border-top: 1px solid $border-color;
      align-items: flex-end;
      .addFile,
      .sendBtn {
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        img {
          width: 40%;
          height: auto;
        }
      }
      .sendBtn {
        background: #464b4e;
      }
      .textArea {
        padding: 6px;
        line-height: 1;
        min-height: 60px;
        max-height: 130px;
        height: 100%;
        width: 0;
        flex: 1;
        border: none;
        display: flex;
        font-size: 15px;
      
      }
    }
    .overlayUncover,
    .chatLearn {
      border-top: 1px solid $border-color;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      &.true {
        border-top: none;
      }
    }
    .overlayUncover {
      background-color: hsla(0, 0%, 100%, 0.9);
      justify-content: center;
      align-items: center;
      z-index: 1;
      text-decoration: underline;
    }
    .chatLearn {
      z-index: 2;
      background: white;
      flex-direction: column;
      .coinHead {
        cursor: pointer;
        margin: 20px 40px 0px 40px;
        border: 1px solid $border-color;
        display: flex;
        height: 100px;
        align-items: center;
        padding: 0 10px;
        .thumb {
          height: 80px;
          width: 80px;
        }
        .textContent {
          padding: 10px 15px;
          .pubTitle {
            font-size: 22px;
            font-weight: 600;
            text-transform: capitalize;
          }
          .pubDesc {
            font-size: 16px;
          }
        }
      }
      .bondsItemHead {
        margin: 10px 40px;
        cursor: pointer;
        border: 1px solid $vault-border-color;
        padding: 3% 4%;
        display: flex;
        flex-direction: column;
        .coinPrice,
        .labels,
        .rates {
          display: flex;
          justify-content: space-between;
          color: $primary-color;
        }
        .coinPrice {
          .img {
            height: 44px;
            display: flex;
            align-items: center;
            font-size: 23px;
            font-weight: bold;
            img {
              height: 80%;
              margin-right: 10px;
            }
          }
          .bondTitle {
            font-size: 23px;
            font-weight: bold;
          }
        }
        .labels {
          font-size: 14px;
        }
        .rates {
          margin-top: 15px;
          .ratesItem {
            .value {
              font-size: 21px;
              font-weight: bold;
            }
            .label {
              font-size: 14px;
            }
          }
        }
        &:hover {
          background: #00000006;
        }
      }
      .whatToDo {
        margin-top: 10px;
        border-top: 1px solid $border-color;
        flex: 1;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        .whatDoTitle {
          font-size: 20px;
          font-weight: 600;
          color: $primary-color;
        }
        .appList {
          margin-top: 20px;
          height: 120px !important;
          > div {
            display: flex;
            .app {
              height: 65px;
              width: 65px;
              flex: 0 0 65px;
              display: flex;
              border-radius: 4px;
              margin-right: 20px;
              position: relative;
              img {
                height: 100%;
                width: 100%;
              }
              .label {
                position: absolute;
                display: none;
                top: 105%;
                width: 100%;
                text-align: center;
                justify-content: center;
              }
              &:hover {
                .label {
                  display: flex;
                }
              }
            }
          }
        }
        .actions {
          margin-top: 20px;
          height: 50px !important;
          > div {
            display: flex;
            align-items: center;
            .btnAction {
              cursor: pointer;
              flex: 0 0 160px;
              height: 85%;
              border: 1px solid $border-color;
              margin-right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 500;
            }
          }
        }
        .actionsList {
          margin-top: 20px;
          height: 0 !important;
          flex: 1;
          > div {
            display: flex;
            flex-direction: column;
            .btnAction {
              cursor: pointer;
              flex: 0 0 85px;
              border: 1px solid $vault-border-color;
              padding: 30px 40px;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-bottom: 20px;
              font-size: 20px;
              font-weight: 600;
              .angle {
                position: absolute;
                right: 50px;
                font-size: 24px;
              }
            }
          }
        }
        .btnGoBack {
          cursor: pointer;
          margin-top: auto;
          margin-bottom: 30px;
          border: 1px solid $border-color;
          height: 65px;
          width: 32%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
          font-weight: 600;
          &:hover {
            background: whitesmoke;
          }
        }
      }
      .pubHead {
        margin: 24px 40px;
        font-weight: 500;
        text-decoration: underline;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 21px;
        .seeAll {
          font-size: 17px;
          text-decoration: none;
        }
      }
      .pubTabs {
        height: 45px;
        border-bottom: 1px solid $border-color;
        border-top: 1px solid $border-color;
        margin-top: 25px;
        display: flex;
        .tab {
          cursor: pointer;
          flex: 0 0 33.33%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &.true {
            font-weight: 600;
            &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 2px;
              background: black;
            }
          }
        }
      }
      .categoryList {
        .categoryItm {
          cursor: pointer;
          margin: 0 40px 15px 40px;
          border: 1px solid $border-color;
          display: flex;
          height: 100px;
          align-items: center;
          padding: 0 10px;
          .thumb {
            height: 80px;
            width: 80px;
          }
          .textContent {
            padding: 10px 15px;
            .pubTitle {
              font-size: 22px;
              font-weight: 600;
              text-transform: capitalize;
            }
            .pubDesc {
              font-size: 16px;
            }
          }
        }
        .bondsItem {
          cursor: pointer;
          border: 1px solid $vault-border-color;
          margin: 20px 40px;
          padding: 3% 4%;
          display: flex;
          flex-direction: column;
          .coinPrice,
          .labels,
          .rates {
            display: flex;
            justify-content: space-between;
            color: $primary-color;
          }
          .coinPrice {
            .img {
              height: 44px;
              display: flex;
              align-items: center;
              font-size: 23px;
              font-weight: bold;
              img {
                height: 80%;
                margin-right: 10px;
              }
            }
            .bondTitle {
              font-size: 23px;
              font-weight: bold;
            }
          }
          .labels {
            font-size: 14px;
          }
          .rates {
            margin-top: 15px;
            .ratesItem {
              .value {
                font-size: 21px;
                font-weight: bold;
              }
              .label {
                font-size: 14px;
              }
            }
          }
          &:hover {
            background: #00000006;
          }
        }
      }
      .categoryListDetail {
        margin: 10px;
        width: unset !important;
        border: 1px solid $border-color;
        .categoryItm {
          position: sticky;
          top: 10px;
          cursor: pointer;
          margin: 10px;
          margin-bottom: 0;
          border: 1px solid $border-color;
          border-bottom: none;
          display: flex;
          height: 100px;
          align-items: center;
          padding: 0 10px;
          z-index: 2;
          background: white;
          .thumb {
            height: 40px;
            width: 40px;
          }
          .textContent {
            padding: 10px 15px;
            .pubTitle {
              font-size: 20px;
              font-weight: 600;
              text-transform: capitalize;
            }
            .pubDesc {
              font-size: 15px;
            }
          }
        }
        .itmDetail {
          margin: 0 10px;
          padding: 0 15px;
          border: 1px $border-color;
          border-style: none solid;
          .listItem {
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
            .key {
              width: 0;
              flex: 0 0 40%;
            }
            .value {
              width: 0;
              flex: 0 0 55%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .btnsDispute {
          display: flex;
          height: 60px;
          margin: 10px;
          margin-top: 0;
          bottom: 10px;
          position: sticky;
          background: white;
          .btnItm {
            border: 1px solid $border-color;
            flex: 0 0 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .headCategory {
        margin: 10px;
        border: 1px solid $border-color;
        display: flex;
        flex: 0 0 80px;
        align-items: center;
        padding: 0 10px;
        .thumb {
          height: 60px;
          width: 60px;
        }
        .textContent {
          padding: 10px 15px;
          .pubTitle {
            font-size: 20px;
            font-weight: 600;
            text-transform: capitalize;
          }
          .pubDesc {
            line-height: 1;
            font-size: 14px;
          }
        }
      }
      .contentType {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        .contentTypeLabel {
          font-size: 14px;
          font-weight: 600;
        }
        .contentBtns {
          height: 40px;
          display: flex;
          justify-content: space-between;
          .btnCntntType {
            cursor: pointer;
            margin-top: 8px;
            border: 1px solid $border-color;
            flex: 0 0 49%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            .cntntIcon {
              height: 17px;
              width: 17px;
              margin-right: 10px;
            }
            &.true {
              border-color: $primary-color;
            }
          }
        }
      }
      .total {
        margin: 15px 10px 15px 10px;
        font-weight: 600;
        font-size: 14px;
      }
      .categoryListVideo {
        margin: 10px 10px 25px 15px;
        width: unset !important;
        height: 0 !important;
        flex: 1;
        > div {
          display: flex;
          padding-bottom: 4px;
          .videoCard {
            border: 1px solid $border-color;
            flex: 0 0 320px;
            margin-right: 50px;
            .videoHolder {
              width: 100%;
              height: fit-content;
              position: relative;
              .videoHolderImg {
                width: 100%;
                height: auto;
              }
              .reactPlayer {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
              }
            }
            .vidTitle {
              margin: 10px;
              font-size: 19px;
              font-weight: 600;
            }
            .desc {
              margin: 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .fooerMenu {
    z-index: 2;
    height: 80px;
    background: $primary-color;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-top: 1px solid white;
    .center {
      width: 100px;
      height: 80px;
      position: relative;

      img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3px;
        height: 100px;
        width: 100px;
        border-radius: 50px;
        background: white;
        padding: 20px;
        padding-right: 28px;
        border: 1px solid $primary-color;
        &.true {
          filter: drop-shadow(0 0 0.5rem $primary-color);
        }
      }
    }
    img {
      cursor: pointer;
      height: 40%;
      width: auto;
      transition: all 300ms ease;
      &.true {
        filter: drop-shadow(0 0 0.5rem white);
        transform: scale(1.1);
      }
    }
  }
}
.chatComponentFull {
  display: flex;
  flex-direction: column;
  width: 100%;
  .head {
    position: relative;
    padding: 20px 100px;
    border-bottom: 1px solid $border-color;
    .title {
      font-weight: 600;
      font-size: 50px;
    }
    .poweredBy {
      display: flex;
      font-size: 24px;
      font-weight: 600;
      color: #166ab3;
      img {
        margin-left: 6px;
        height: 24px;
      }
    }
    .sizeIcon {
      position: absolute;
      top: 20px;
      bottom: 20px;
      right: 100px;
      height: 30px;
      margin: auto;
    }
  }
  .wrapper {
    flex: 1;
    display: flex;
    .humanChatAreaFull {
      flex: 1;
      display: flex;
      flex-direction: column;
      .chatView {
        height: 0 !important;
        flex: 1;
        .question {
          margin: 0 3%;
          margin-top: 3%;
          display: flex;
          .bot {
            width: 70px;
            display: flex;
            flex-direction: column;
            .img-logo {
              margin: 0 auto;
              height: 50px;
              width: 50px;
              padding: 5px;
              background: $primary-color;
              border-radius: 25px;
            }
            p {
              text-align: center;
              font-size: 11px;
            }
          }
          .question-wrap {
            margin: auto auto auto 8px;
            padding: 12px;
            min-width: 20%;
            max-width: 60%;
            background: #e8eaef50;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 20px;
            display: flex;
            flex-direction: column;
            .question-text {
              width: 100%;
              font-size: 14px;
              word-break: break-word;
              margin: 0;
            }
            .preview {
              width: 100%;
              height: fit-content;
              position: relative;
              svg {
                width: 100%;
                height: auto;
              }
              .previewItem {
                object-fit: contain;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                &.file {
                  padding: 35px;
                }
              }
            }
          }
          .question-loading {
            width: 100px;
            height: 40px;
            margin-left: 16px;
            margin-right: auto;
            padding: 0 12px;
            display: flex;
            align-items: center;
            background: #e8eaef50;
            font-size: 14px;
            border-radius: 30px;
            .lds-ellipsis {
              display: inline-block;
              position: relative;
              width: 60px;
              height: 20px;
              div {
                position: absolute;
                top: 6px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: rgb(177, 177, 177);
                animation-timing-function: cubic-bezier(0, 1, 1, 0);

                &:nth-child(1) {
                  left: 8px;
                  animation: lds-ellipsis1 0.6s infinite;
                }

                &:nth-child(2) {
                  left: 8px;
                  animation: lds-ellipsis2 0.6s infinite;
                }

                &:nth-child(3) {
                  left: 32px;
                  animation: lds-ellipsis2 0.6s infinite;
                }

                &:nth-child(4) {
                  left: 56px;
                  animation: lds-ellipsis3 0.6s infinite;
                }
              }
            }

            @keyframes lds-ellipsis1 {
              0% {
                transform: scale(0);
              }

              100% {
                transform: scale(1);
              }
            }

            @keyframes lds-ellipsis3 {
              0% {
                transform: scale(1);
              }

              100% {
                transform: scale(0);
              }
            }

            @keyframes lds-ellipsis2 {
              0% {
                transform: translate(0, 0);
              }

              100% {
                transform: translate(24px, 0);
              }
            }
          }
        }
        .answer {
          margin: 0 3%;
          margin-top: 3%;
          display: flex;
          .bot {
            width: 70px;
            display: flex;
            flex-direction: column;
            .img-logo {
              margin: 0 auto;
              background-color: $primary-color;
              height: 50px;
              width: 50px;
              border-radius: 25px;
            }
            p {
              text-align: center;
              font-size: 11px;
            }
          }
          .question-wrap {
            margin: auto 8px auto auto;
            padding: 12px;
            min-width: 20%;
            max-width: 60%;
            height: fit-content;
            background: white;
            border: 1px solid $border-color;
            border-bottom-left-radius: 20px;
            border-top-right-radius: 20px;
            display: flex;
            flex-direction: column;
            .question-text {
              width: 100%;
              font-size: 14px;
              word-break: break-word;
              margin: 0;
            }
            .preview {
              width: 100%;
              height: fit-content;
              position: relative;
              svg {
                width: 100%;
                height: auto;
              }
              .previewItem {
                object-fit: contain;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                &.file {
                  padding: 35px;
                }
              }
            }
          }
          .question-text {
            font-size: 14px;
            word-break: break-word;
          }
        }
      }
      .chatInputs {
        display: flex;
        border-top: 1px solid $border-color;
        align-items: flex-end;
        .addFile,
        .sendBtn {
          height: 60px;
          width: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          img {
            width: 40%;
            height: auto;
          }
        }
        .sendBtn {
          background: #464b4e;
        }
        .textArea {
          padding: 6px;
          line-height: 1;
          min-height: 60px;
          max-height: 130px;
          height: 100%;
          width: 0;
          flex: 1;
          border: none;
          display: flex;
          font-size: 15px;
          
        }
      }
    }
    .chatComponent {
      min-width: 40%;
    }
  }
}
@keyframes chatShow {
  from {
    max-width: 0%;
  }
  to {
    max-width: 32%;
  }
}
