.leftComponent {
  width: 25%;
  border-right: 0.5px solid #e7e7e7;
  height: 100%;

  & > ul {
    list-style: none;
    overflow: hidden;

    & > li {
      //   width: 5rem;
      padding: 2rem 4vw;
      font-size: 1.5rem;
      font-weight: 550;
      display: flex;
      align-items: center;
      color: #5f6163;
      position: relative;
      cursor: pointer;
      transition: all ease-in 0.4s;

      &:hover {
        transform: translate(20px);
      }
    }

    & > * {
      opacity: 0.5;
    }

    & > :nth-child(1) {
      opacity: 1;

      &::after {
        content: '';
        position: absolute;
        width: 4px;
        background: #4caf50;
        height: 50%;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.rightComponent {
  width: 75%;
  height: 100%;
  overflow-y: scroll;
  //   border: 1px solid green;
}

.detailedDiv {
  height: max-content;
  padding: 3.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  border-bottom: 0.5px solid #e7e7e7;
  // transition: all ease-in 0.2s;

  &:hover {
    background: rgb(95, 97, 99, 0.3);
  }

  & > .topDiv {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    font-weight: 500;
    color: #5f6163;
    cursor: pointer;
    // padding: 0 1.5rem;
    transition: all ease-in 0.4s;

    & > :nth-child(2) {
      cursor: pointer;
      transition: all ease 0.4s;
      //   padding: 0.2rem;
      //   border-radius: 50%;

      &:hover {
        // color: white;
        // background: #5f6163;
        scale: 1.15;
      }
    }
  }

  & > .bottomDivParent {
    display: flex;
    gap: 3rem;
    overflow-y: scroll;

    & > .bottomDiv {
      width: 40%;
      min-width: 500px;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      padding: 2rem;
      border-radius: 10px;
      color: #5f6163;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      & > .title {
        font-size: 1.5rem;
        font-weight: 550;
      }
      & > .para {
        font-size: 1rem;
      }
      & > .btnDiv {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;

        & > :nth-child(1) {
          padding: 1.2rem;
          border-radius: 50%;
          background: #f3f4f5;

          & > svg {
            font-size: 1.2rem;
          }
        }

        & > :nth-child(2) {
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 4rem;
            height: 4rem;
          }
        }
      }
    }
  }
}

.productsInvestor {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}

.investorContainer {
  width: 50%;
  padding: 5% 4%;
  display: flex;
  flex-direction: column;
  gap: 7%;
  justify-content: center;
  // border: 1px solid green;

  & > .title {
    font-size: 2rem;
    font-weight: 600;
    // border-left: 5px solid #4caf50;
    position: relative;
    padding-left: 15px;

    &::before {
      content: '';
      position: absolute;
      width: 5px;
      height: 65%;
      background: linear-gradient(
        90.43deg,
        #4caf50 3.17%,
        #4caf50 3.18%,
        #93d996 105.2%
      );
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  & > .titleWhite {
    font-size: 2rem;
    font-weight: 600;
    // border-left: 5px solid #4caf50;
    position: relative;
    padding-left: 15px;

    &::before {
      content: '';
      position: absolute;
      width: 5px;
      height: 65%;
      background: white;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
  & > .ques {
    font-size: 3.7rem;
    font-weight: 650;
    line-height: 1.2;
  }
  & > .para {
    font-size: 1.5rem;
    line-height: 1.75;
  }
  & > .btn {
    padding: 2% 6%;
    font-size: 1.6rem;
    font-weight: 700;
    background: linear-gradient(
      270.43deg,
      #4caf50 3.17%,
      #4caf50 3.18%,
      #93d996 105.2%
    );
    border-radius: 10px;
    width: max-content;
    color: white;
    cursor: pointer;
    transition: all ease-in 0.4s;

    &:hover {
      // font-size: 1.65rem;
      scale: 1.05;
    }
  }
}
