.sidemenu {
  width: 500px;
  height: 100vh;
  top: 0;
  position: fixed;
  background: white;
  
  z-index: 11;
  text-align: start;
  border: 1px solid #ededed;
  
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  
  border: 0.5px solid #e5e5e5;
  
}

.pagemask {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden !important;
}

.close-img {
  margin-top: -138px;
  margin-left: -30px;
}

.side-text {
  margin-left: 50px;
}

.card-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #5f6163;
  margin-top: 15px;
}

.card-desc {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #5f6163;
}

.card-data {
  display: flex;
  margin: 5% 0;
  transition: -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-data:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.4s all ease;
}

.company-logo {
  border: 0.5px solid #e5e5e5;
  border-radius: 5px;
  padding: 13px;
  width: 52px;
  height: 52px;
}

.card-text {
  margin-left: 10px;
}

.comp-title {
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #5f6163;
  margin-top: 10px;
}

.comp-desc {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #5f6163;
  margin-top: -11px;
}

.rightmenu {
  width: 500px;
  height: 100vh;
  position: fixed;
  background: white;
  padding-top: 25px;
  z-index: 11;
  text-align: start;
  border: 1px solid #ededed;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  border: 0.5px solid #e5e5e5;
  top: 0;
  left: 0;
}

.close-img-right {
  margin-top: -138px;
  margin-left: 503px;
}

.right-card-title {
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  display: flex;
  align-items: center;
  color: #5f6163;
}

.right-card-desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #5f6163;
  margin-top: -13px;
}

.left-icon,
.right-logo {
  margin-left: 13.5px;
  margin-right: 14px;
}

.right-logo:hover,
.left-icon:hover {
  transform: scale(1.1);
  transition: 0.4s all ease;
}

.right-icons {
  display: flex;
  margin-left: auto;
}

@media screen and (min-width: 1600px) {
  .left-icon,
  .right-logo {
    margin-left: 25.5px;
    margin-right: 26px;
  }

}
