.stickyFooter {
  height: 45px;
  background: #4caf50;
  border: 0.5px solid #4caf50;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem 1.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    & > svg {
      color: white;
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 900;
      transition: all ease-in 0.2s;
    }

    & > .leftArrow {
      &:hover {
        transform: translateX(-5px);
      }
    }
    & > .rightArrow {
      &:hover {
        transform: translateX(5px);
      }
    }
  }

  & > .contentDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & > div {
      font-size: 1rem;
      font-weight: 700;
      color: white;
    }

    & > img {
      height: 40%;
      object-fit: contain;
    }
  }
}
