.downloadFooter {
  height: calc(100vh - 163px);
  position: relative;

  //   border: 1px solid red;

  & > .contentDiv {
    height: 65%;
    padding: 2%;
    background: linear-gradient(
      270.43deg,
      #4caf50 3.17%,
      #4caf50 3.18%,
      #93d996 105.2%
    );
    font-size: 5rem;
    font-weight: 700;
    color: white;
    display: flex;
    align-items: center;
padding-left: 6.5vw;
padding-right: 6.5vw;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 60%;

      & > :nth-child(1) {
        line-height: 1.1;
      }

      & > .para {
        font-size: 1.75rem;
      }

      & > .downloadApp {
        display: flex;
        justify-content: space-between;
        width: max-content;
        height: 60px;
        margin-top: 1.5rem;
        // min-width: 40%;
        gap: 2.5rem;
        position: relative;

        & > div {
          padding: 0% 5%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          & > img {
            height: 70%;
            object-fit: contain;
            cursor: pointer;
            transition: all ease-in 0.4s;

            &:hover {
              scale: 1.05;
            }
          }
        }

        & > :nth-child(1) {
          border-right: 2px solid #ffffff;
          padding-left: 0;
          padding-right: 10%;
        }

        & > :nth-child(2) {
          padding-left: 4%;
        }
        // & > .borderDiv {
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        //   width: 2px;
        //   background: white;
        //   height: 100%;
        // }
      }
    }
  }

  & > img {
    position: absolute;
    top: 6%;
    right: 0;
    height: 87.5%;
  }
}
