.commonSectionContainer {
  height: 70%;
  // border: 1px solid red;
  display: flex;

  & > .contentDiv {
    width: 70%;
    height: 100%;
    padding: 2.5% 6.5vw;
    // border-top: 0.5px solid #e5e5e5;
    border-bottom: 0.5px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 3rem;

    & > :nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      & > .heading {
        font-size: 2.5rem;
        font-weight: 650;
        color: #5f6163;
      }

      & > .para {
        font-size: 1.4rem;
        font-weight: 400;
        color: #5f6163;
      }
    }

    & > .nextDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: #f3f4f5;
      gap: 1rem;
      cursor: pointer;
      transition: all ease-in 0.3s;

      &:hover {
        background-color: linear-gradient(
          270.43deg,
          #4caf50 3.17%,
          #4caf50 3.18%,
          #93d996 105.2%
        );
        width: max-content;
        padding: 0 2%;
        border-radius: 30px;
      }

      &:hover > div {
        display: flex;
      }

      & > div {
        display: none;
        font-size: 1.2rem;
        font-weight: 700;
        color: #4caf50;
      }

      & > img {
        height: 40%;
      }
    }
  }

  & > .imageDiv {
    width: 30%;
    height: 100%;
    // margin-right: 0;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
