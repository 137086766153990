.updatesContainer{
    display: flex;
    justify-content: center;
    padding: 30px 0px;
}
.updatesWidth{
    width: 100%;
    max-width: 1000px;
    // background-color: red;
    border-left: 2px solid #eee;
    padding-left: 40px;

}
.updateCard{
    border: 1.4px solid #e7e7e7;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10vh;
    padding: 32px;
   
}
.updateDate{
    position: absolute;
    margin-left: -130px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: white;
    :nth-child(1){
        font-size: 25px;
        font-weight: 400;
    }
    :nth-child(2){
        font-size: 13px;
        color: #aaa;
        font-weight: 400;
    }
}
.updateTitle{
    font-size: 35px;
    font-weight: 800;
    margin-bottom: 10px;
    color: black;
}
.updateDesc{
    font-size: 16px;
    // font-weight: 500;
    margin-bottom: 10px;
}
.authorDetails{
    display: flex;
    align-items: center;
    padding: 40px 0px;
}
.authorImg{
    width: 48px;
    min-width: 48px;
    height: 48px;
    margin-right: 10px;
    img{
        width: 100%;
        height: 100%;
        // border-radius: 50%;
    }
}
.authorName{
    font-size: 18px;
    font-weight: 900;  
}

.updateFooter{
    display: flex;
    align-items: center;
}

.likeContainer{
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    color: #f83a5c;
    :hover{
       opacity: 0.7;
    }
    .likeIcon{
        margin-right: 5px;
    }
}
.commentContainer{
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    color: #0049ff;
    :hover{
       opacity: 0.7;
    }
    .commentIcon{
        margin-right: 5px;
    }
}