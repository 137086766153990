html {
  --max-container-width: 82vw;
  scroll-behavior: smooth;
  --navBtn-width: 10vw;
  --section-font-size-mobileL1: 38px;
  --section-font-size-mobileS1: 24px;
  --section-font-size-mobileN1: 12px;
  /* --section-font-size-mobileL2: 30px;
  --section-font-size-mobileS2: 20px;
  --section-font-size-mobileN2: 12px */
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.centerCommon {
  display: flex;
  justify-content: center;
  align-items: center;
}




@media screen and (max-width:850px) {
  html {
    --section-font-size-mobileL1: 45px;
    --section-font-size-mobileS1: 28px;
    --section-font-size-mobileN1: 16px;
  }

  .introDiv>div:last-child>div:first-child {
    height: 60px;
  }

  .introDiv>div:last-child>div:last-child {
    height: 60px;
  }

  .introDiv>div:last-child {
    max-width: 360px;
  }

  .introDiv>div:first-child {
    gap: 20px;
  }

  .introDiv {
    gap: 30px;
  }

}

@media screen and (max-width:480px) {
  html {
    --section-font-size-mobileL1: 38px;
    --section-font-size-mobileS1: 22px;
    --section-font-size-mobileN1: 12px;
  }

  /* .introDiv>div:last-child>div:first-child {
    height: 30px;
  }

  .introDiv>div:last-child>div:last-child {
    height: 30px;
  } */

  .introDiv>div:last-child {
    max-width: 280px;
  }

  .greenSectionM {
    margin-top: 0;
  }

  .introDiv>div:first-child {
    gap: 10px;
  }

  .introDiv {
    gap: 20px;
  }
}

@media screen and (max-width:1100px) {
  .imageDiv-LP {
    top: 6vw;
  }
}

@media screen and (max-width:900px) {
  .imageDiv-LP {
    top: 4vw;
    bottom: 13vh;
    margin-top: 50px;
  }
}

.miniNavbarM>ul::-webkit-scrollbar {
  display: none;
}

.miniNavbarM>ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (min-width: 2600px) {
  html {
    font-size: 0.7vw;
  }
}

@media screen and (min-width: 2200px) and (max-width:2600px) {
  html {
    font-size: 110%;
  }
}

@media screen and (min-width: 1850px) and (max-width:2200px) {
  html {
    font-size: 100%;
  }
}

@media screen and (min-width: 1601px) and (max-width:1850px) {
  html {
    font-size: 90%;
  }
}

@media screen and (min-width: 1501px) and (max-width:1600px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-width: 1401px) and (max-width:1500px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-width: 1301px) and (max-width:1400px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-width:1201px) and (max-width:1300px) {
  html {
    font-size: 70%;
  }
}

@media screen and (min-width:1101px) and (max-width:1200px) {
  html {
    font-size: 60%;
  }
}

@media screen and (min-width:1000px) and (max-width:1200px) {
  html {
    font-size: 55%;
  }
}