.homePage {
  height: 0;
  flex: 1;
  overflow-y: scroll;

  & > .landingPageContainer {
    position: relative;
    width: 100vw;
    height: calc(100vh - 163px);
    margin-top: 103px;

    & > .question {
      font-size: 2rem;
      font-weight: 650;
      color: #5f6163;
      padding: 2rem 0;
      padding-left: 3.5%;
    }
  }
}

.stickyFooter {
  flex: 0 0 65px;
  max-height: 60px;
  // background: #4caf50;
  background: linear-gradient(
    270.43deg,
    #4caf50 3.17%,
    #4caf50 3.18%,
    #93d996 105.2%
  );
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 19px;
  font-weight: 600;
  bottom: 0;
  position: sticky;
  .footerWrap {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .raindrops {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 1%;
      width: 15%;
      padding-bottom: 10px;
    }
    &.sb {
      justify-content: space-between;
      .left,
      .right {
        height: 14px;
        width: 16px;
      }
      .banner {
        display: flex;
        align-items: center;
        height: 50px;
        width: fit-content;
        .logo {
          // height: 50px;
          // width: 50px;
          margin-right: 10px;
          height: 45%;
          object-fit: contain;
        }
        span {
          font-size: 1.2rem;
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}
