.rootFile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 17rem;
  
    padding-bottom: 12rem;
    padding-left: calc(10vw / 2.5) !important;
    padding-right: calc(10vw / 2) !important;
  
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 73px;
      color: #52b357;
      width: 753px;
      margin-bottom: 2.4rem;
    }
   
    p {
      width: 810px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 2.4rem;
      color: #000000;
    }
    label {
      width: 152px;
      height: 52px;
      margin-top: 1rem;
      align-items: center;
      justify-content: center;
      display: flex;
      background: #73c676;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
      color: #ffffff;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  
  .discoverSetion {
    background: #73c676;
    padding-left: calc(10vw / 2.5) !important;
    padding-right: calc(10vw / 2) !important;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .sectionHanding{
      display:flex;
      justify-content: space-between;
    }
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
  
      color: #ffffff;
  
      text-align: center;
    }
    p {
      width: 427px;
  
      left: 138px;
      top: 1067px;
  
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
  
      color: #ffffff;
    }
    h1 {
      width: 330px;
  
      left: 138px;
      top: 950px;
  
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 49px;
  
      color: #ffffff;
      margin-bottom: 2rem;
    }
    .tabLayout {
      display: flex;
      align-items: center;
      // gap: 2rem;
      justify-content: center;
      margin-top: 5rem;
      margin-bottom: 8rem;
  
      span {
        width: 86px;
        height: 1px;
        background: #000000;
      }
      div {
        display: flex;
        align-items: center;
        border-radius: 20px;
        justify-content: center;
        height: 40px;
        padding: 0 15px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
  
        color: #000000 !important;
        background: #cfedd080;
        //   background: #ffffff;
        //   mix-blend-mode: soft-light;
        color: #000000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  
  .marketSection {
    padding-top: 5rem;
    padding-bottom: 6rem;
    .Info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 80rem;
      margin: auto;
    }
    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 73px;
      text-align: center;
      text-align: center;
      color: #52b357;
    }
    .rightSide {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 379px;
      }
      div {
        width: 379px;
        height: 67px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        cursor: pointer;
        color: #4caf50;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1px solid #4caf50;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 58px;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .Info {
      display: flex;
      justify-content: space-between;
    }
    h2 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 45px;
      line-height: 55px;
      width: 392px;
      color: #4caf50;
    }
    p {
      width: 427px;
  
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;
      margin-top: 1rem;
      color: #000000;
    }
    .tab {
      display: flex;
      align-items: center;
      grid-gap: 3rem;
      gap: 3rem;
      justify-content: center;
      margin-top: 4rem;
      padding-bottom: 7rem;
      div {
        height: 34px;
        border: 1px solid #000000;
        border-radius: 41px;
        padding: 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 28px;
        cursor: pointer;
        color: #000000;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .boderbanner {
    width: 100%;
    padding: 0.5rem;
    background: #73c676;
  }
  .footerSection {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: calc(10vw / 2.5) !important;
    padding-right: calc(10vw / 2) !important;
    margin-top: 5rem;
    margin-bottom: 5rem;
    .socalMedia {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 1.5rem;
      color: #000000;
    }
  }
  .accessBanner {
    width: 100%;
  
    display: flex;
    align-items: center;
    justify-content: center;
    background: #73c676;
    margin-bottom: 2rem;
    padding: 2.5rem 0rem;
    margin-bottom: 2rem;
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      width: 210px;
      height: 52px;
      background: #ffffff;
      border-radius: 8px;
      color: #73c676;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  
  .bottomContentSection {
    padding-bottom: 4rem;
    padding-top: 4rem;
    padding-left: calc(10vw / 2.5) !important;
    padding-right: calc(10vw / 2) !important;
    p {
      width: 831px;
  
      left: 62px;
      top: 2980px;
  
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
  
      color: #000000;
    }
    h1 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 1.4rem;
  
      color: #000000;
    }
    .leftsideHolder {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }