.diffContainer {
  //   min-height: calc(100vh - 163px);
  //   border: 1px solid red;
  display: flex;
  border-top: 0.5px solid #e5e5e5;
  border-bottom: 0.5px solid #e5e5e5;
  //   padding: 0 6.5vw;
}

.singleDiffDiv {
  width: 100%;
  padding: 3% 6.5vw;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  //   justify-content: center;
  //   border: 1px solid red;

  & > .topDiv {
    & > img {
      height: 3.5rem;
      width: 3.5rem;
      object-fit: contain;
    }
    & > div {
      font-size: 1.7rem;
      font-weight: 650;
      color: #5f6163;
      margin-top: 1.5rem;
    }
  }

  & > .bottomDiv {
    & > .para {
      font-size: 1.2rem;
      font-weight: 400;
      color: #5f6163;
    }

    & > .itemsContainer {
      margin-top: 1.5rem;
      height: inherit;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > .eachItem {
        padding: 1.2rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 600;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        color: #5f6163;
        transition: all ease-in 0.4s;
        cursor: pointer;

        &:hover {
          background-color: rgba(95, 97, 99, 0.3);
        }
      }
    }
  }
}
