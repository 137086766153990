.signUpPage {
  flex: 1;
  display: flex;
  .loginSideWrap {
    display: flex;
    flex-direction: column;
    flex: 0 0 40%;
    .mobileLogin {
      width: unset;
      flex: 1;
      padding: 0 16%;
      .login-enter {
        .stepContent {
          .group {
            margin-bottom: 30px;
            .domain {
              margin-right: -60px;
              font-weight: 500;
              position: relative;
              .eye {
                left: -20px;
                right: unset;
              }
            }
          }
          .info {
            font-size: 14px;
            margin-bottom: 30px;
          }
        }
      }
    }
    .footer {
      height: 70px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 500;
      .styled-checkbox {
        display: none;
        & + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          margin: 0;
          font-weight: 500;
          color: white;
        }

        // Box.
        & + label:before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 20px;
          height: 20px;
          background: white;
        }

        // Box hover
        &:hover + label:before {
          background: #f35429;
        }

        // Box focus
        &:focus + label:before {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        // Box checked
        &:checked + label:before {
          background: #f35429;
        }

        // Disabled state label.
        &:disabled + label {
          color: #b8b8b8;
          cursor: auto;
        }

        // Disabled box.
        &:disabled + label:before {
          box-shadow: none;
          background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
          content: '';
          position: absolute;
          left: 5px;
          top: 11px;
          background: white;
          width: 2px;
          height: 2px;
          box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
            4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
          transform: rotate(45deg);
        }
      }
    }
  }
  .sideFullImg {
    flex: 1;
  }
  .bottomBtn {
    display: none;
  }
  @include md {
    display: flex;
    flex-direction: column;
    .mobileLogin {
      height: 0;
      flex: 1;
    }
    .sideFullImg {
      display: none;
    }
  }
}
