.navBtn {
  font-weight: bold;
}

a {
  all: unset;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.scrollNavbar {
  width: 100%;
  height: 36px;
  display: flex;
  background: #ffffff;
  border-bottom: 0.5px solid #e5e5e5;
  gap: 0 5px;
  position: fixed;
  z-index: 2;
  padding-left: 0.5%;
}

.scrollNavbarItems {
  cursor: pointer;
  white-space: nowrap;
  min-width: 10%;
  height: 100%;
  text-align: center;
  color: #5f6163;
  padding: 5px;
  transition: all 0.5s;
}

.scrollNavbarItems:hover {
  color: #5f6163;
  text-decoration: none;
  font-weight: bold;
}

.selected {
  font-weight: bold;
  font-size: 18px;
}

.d-flex>div:hover {
  cursor: pointer;
  scale: 1.04;
}

.loadingImageDiv {
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
  color: #ffffff;
}

.productDropdown {
  width: 100%;
  background: white;
  height: 35vh;
  transition: all ease 0.6s;
  display: flex;
  max-height: 350px;
}

.productDropdownFirst {
  /* min-width: 333px; */
  width: 23.05%;
  height: 100%;
  padding: 20px 0px;
  /* padding-left: calc(10vw / 2.5) !important; */
  border-right: 0.5px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  justify-content: space-around;
  cursor: pointer;
}

.selectedOpt-div {
  width: calc(100% - 23.05%);
  height: 100%;
  padding: 2.5rem 2rem;
  display: flex;
  overflow-x: scroll;
  gap: 2rem;
}

.selectedOpt {
  min-width: 25rem;
  /* height: 100%; */
  width: 25rem;
  border: 0.5px solid #e5e5e5;
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #5f6163;
  z-index: 20;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all ease-in 0.4s;
}

.margintop {
  align-items: center;
  transition: all ease-in 0.3s;
}

.hover-show {
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  transition: all ease 0.4s;
  left: 0;
  gap: 1.5rem;
}

.zoom-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.selectedOpt:hover {
  box-shadow: 0 12px 28px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 10%),
    inset 0 0 0 1px hsl(0deg 0% 100% / 5%);
}

.zoom1 {
  background: #f3f4f5;
  border: 0;
  border-radius: 30px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  width: 40px;
}

.check1-text {
  display: none;
}

.clickarrow-img {
  height: 20px;
  width: 20px;
}

.zoom2 {
  display: block;
  width: fit-content;
}

.icon-div {
  transition: all ease 0.4s;
}

.selectedOpt:hover .check1-text {
  color: #182542;
  display: inline-flex;
  margin-left: 9px;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 700;
}

.selectedOpt:hover .clickarrow-img {
  margin-left: 0.2rem;
  margin-top: 0.3rem;
  width: 20px;
}

.selectedOpt:hover .zoom1 {
  background: #fffbf0;
  height: 40px;
  text-align: center;
  width: 120px;
}

.selectedOpt:hover .icon-div {
  scale: 1.1;
}

.brand-name {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img-div {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.productDropdownFirst>a {
  width: 100%;
  height: 12%;
  display: flex;
  align-items: center;
  padding: 5px 0;
  padding-left: calc(10vw / 2.5) !important;
  /* transition: all ease-in 0.1s; */
}

.productDropdownFirst>div {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  padding: 5px 0;
  padding-left: calc(10vw / 2.5) !important;
  /* transition: all ease-in 0.1s; */
}

.productDropdownFirst>div:hover {
  background-color: rgba(24, 25, 29, 0.1);
}

.productDropdownFirst>a:hover {
  background-color: rgba(24, 25, 29, 0.1);
}

.productDropdownFirst img {
  padding: 5px;
  width: 12vw;
  max-width: 100%;
  object-fit: contain;
  height: 80%;
  padding: 5px 0;
}

.dropdownactive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.5;
  transition: all ease 0.6s;
  z-index: 1;
}

.selectedDropdown {
  border-right: 3px solid #4caf50;
  font-weight: 700;
}


/* @media (min-width:1900px) and (max-width:) {} */