@import '../../static/scss/colours';
@import '../../static/scss/mixin';
@import '../../static/scss/hamburgers';

.navWrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 4;
  .topBar {
    z-index: 2;
    background: white;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .topBarSubItem {
      padding: 0.5rem;
      display: flex;
      justify-content: center;
      border-right: 0.5px solid #e5e5e5;
      min-width: 75px;
    }
    .menu {
      margin-left: auto;
      display: flex;
      align-items: center;
      .menuItem {
        display: flex;
        text-decoration: none;
        margin-left: 40px;
        color: white;
        font-weight: normal;
        cursor: pointer;
        transition: all 400ms ease;
        align-items: center;
        svg {
          height: 18px;
          width: auto;
          margin-right: 6px;
        }
        &:hover {
          transform: scale(1.05);
        }
        &.true {
          font-weight: bold;
        }
      }
    }
  }
  .mainNavbar {
    z-index: 2;
    background: white;
    height: 67px;
    display: flex;
    align-items: center;
    border: 0.5px solid #e5e5e5;
    border-left: none;
    .logo {
      height: 60%;
      width: 250px;
      img {
        height: 100%;
        transition: all 400ms ease;
      }
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
      .topLogo {
        display: none;
      }
    }
    .navMenu {
      margin-left: auto;
      display: flex;
      align-items: center;
      color: $primary-color;
      width: 100%;
      justify-content: space-between;
      .searchInput {
        background-color: transparent;
        border: 0px solid;
        border-left: 0.5px solid #e5e5e5;
        height: 67px;
        color: #000;
        width: 100%;
        margin-left: 2rem;
        padding-left: 1rem;
      }
      .register {
        background-color: $app-color;
        color: white;
      }

      .navMenuItem {
        display: flex;
        height: 67px;
        min-width: 150px;
        justify-content: space-around;
        align-items: center;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          font-weight: 600;

          text-decoration: none;
          color: inherit;
        }
        &.true {
          font-weight: bold;
        }
      }

      .navMenuItemDiv {
        height: 67px;
        display: flex;
        border-left: 0.5px solid #e5e5e5;
      }

      .navItem {
        font-size: 19px;
        font-weight: 500;
        margin-left: 50px;
        line-height: 1;
        color: $primary-color;
        height: fit-content;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          font-weight: 600;
          transform: scale(1.05);
        }
        &.true {
          font-weight: bold;
        }

        &.btn {
          color: white;
          transform: unset;
          .btnIn {
            min-width: 140px;
            height: 45px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 2rem;
            background: $app-color;
            color: white;
            cursor: pointer;
            font-size: 17px;
            font-weight: bold;
            @include btn-animate(white);
          }
          &.appMenu {
            margin-left: 15px;
          }
        }
        &.login {
          color: $primary-color;
          transform: unset;
          .btnIn {
            border: 1px solid $border-color;
            background: white;
            color: $primary-color;
            @include btn-animate;
          }
        }
      }
    }
    .overlay {
      z-index: 5;
      position: fixed;
      left: 0;
      right: 0;
      top: 120px;
      bottom: 0;
      background: $overlay-color;
      .openNav {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: fit-content;
      }
    }
    .hamburger {
      display: none;
    }
    .footerBtn {
      display: none;
    }
  }
  .btmBar {
    flex: 0 0 36px;
    background-color: #f2f2f2;
    padding: 0 6%;
    display: flex;
    align-items: center;
    .menu {
      flex: 1;
      margin-left: auto;
      display: flex;
      align-items: center;
      .menuItem {
        cursor: pointer;
        font-size: 16px;
        color: $primary-color;
        font-weight: normal;
        margin-right: 40px;
        transition: all 400ms ease;
        &:hover {
          transform: scale(1.05);
        }
        &.true {
          font-weight: bold;
        }
      }
    }
  }

  @include xl {
    .topBar {
      .topBarSubItem {
        min-width: 50px;
      }
    }
    .mainNavbar {
      .navMenu {
        .searchInput {
          width: 70%;
        }
        .navMenuItem {
          min-width: 100px;
        }
      }
    }
  }

  @include lg {
    flex: 0 0 80px;
    .topBar {
      display: none;
    }
    .mainNavbar {
      position: sticky;
      top: 0;
      background: white;
      height: 80px;
      flex: 0 0 80px;
      border-bottom: 1px solid $border-color;
      .topLogo {
        width: 60%;
        height: auto;
        margin: auto;
        img {
          width: 100%;
        }
      }
      .navMenu {
        display: none;
      }
      .hamburger {
        display: flex;
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: fit-content;
      }
    }
  }
}

.sidebarMobile {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $overlay-color;
    z-index: 0;
  }
  .menuWrap {
    z-index: 1;
    width: 80%;
    height: 100%;
    background: white;
    position: relative;
    display: flex;
    flex-direction: column;
    .header {
      flex: 0 0 80px;
      background: $app-color;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      color: white;
      padding: 0 40px;
      .icon {
        height: 18px;
        width: auto;
        margin-left: 8px;
        path {
          fill: white;
        }
      }
    }
    .navMenu {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $primary-color;
      padding: 40px;
      overflow-y: auto;
      margin-top: 50px;
      .searchInput {
        background-color: transparent;
        border: 0px solid;
        height: 67px;
        font-size: 19px;
        color: #000;
        width: 100%;
      }
      .navItem {
        flex: 0 0 12%;
        font-size: 19px;
        font-weight: 500;
        line-height: 1;
        color: $primary-color;
        height: fit-content;
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
          font-weight: 600;
          transform: scale(1.05);
        }
        &.true {
          font-weight: bold;
        }
        &.btn {
          color: white;
          transform: unset;
          .btnIn {
            min-width: 180px;
            height: 50px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 2rem;
            background: $app-color;
            color: white;
            cursor: pointer;
            @include btn-animate(white);
          }
        }
      }
    }
    .btnIos,
    .btnAndroid {
      height: 60px;
      padding: 0 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $text-color;
      margin: 0;
      border: 1px solid $border-color;
      border-radius: 6px;
      width: 100%;
      @include btn-animate($primary-color);
      margin: auto 0;
      .icon {
        height: 45%;
        width: 30px;
        object-fit: contain;
      }
      .texts {
        margin-left: 6px;
        .downOn {
          font-size: 11px;
          font-weight: 500;
          line-height: 1;
        }
        .sorce {
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
        }
      }
    }
  }
}
