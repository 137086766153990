.impage {
  display: flex;
  gap: 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   border: 1px solid red;
  height: calc(100% - 76px);

  & > img {
    width: 27.5%;
    object-fit: contain;
    max-width: 600px;
  }

  & > .searchDiv {
    position: relative;
    // border: 1px solid red;
    border-bottom: 1px solid #e5e5e5;
    height: 4rem;
    width: 50%;
    font-size: 1.5rem;

    & > input {
      outline: none;
      border: none;
      height: 100%;
      width: 100%;
      transition: all ease-in 0.4s;

      &:hover {
        background: #e5e5e5;
      }
    }

    & > svg {
      position: absolute;
      top: 50%;
      //   left: 50%;
      right: 10px;
      transform: translate(-50%, -50%);
      height: 3rem;
      width: 2rem;
      cursor: pointer;
    }
  }

  & > .contentDiv {
    width: 60%;
    min-height: 35%;
    max-height: fit-content;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    overflow-x: scroll;
    display: flex;
    gap: 1.5rem;
  }
}

.eachCard {
  //   border: 1px solid red;
  color: #5f6163;
  padding: 1.5rem;

  & > img {
    width: 10rem;
    height: 10rem;
  }

  & > .title {
    margin-top: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
  }
  & > .location {
    font-size: 1.2rem;
    font-weight: 500;
  }
  & > .asset {
    margin-top: 1rem;
    font-size: 1rem;
  }
}
