.directoryContainer {
  // border: 1px solid blue;
  height: 100%;

  & > .directoryTopContainer {
    height: 10%;
    // max-height: 300px;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #f9f9f9;
    justify-content: flex-end;

    & > .title {
      font-size: 2.2rem;
      font-weight: 800;
      padding-left: 6.5vw;
      color: #5f6163;
      height: 65%;
      display: flex;
      align-items: center;
    }

    & > .optionsContainer {
      display: flex;
      // margin-top: 10px;

      & > div {
        font-size: 1.2rem;
        font-weight: 700;
        padding: 1.2rem 3.5rem;
        color: #5f6163;
        cursor: pointer;
      }

      & > .selected {
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 0px 15px 0px 0px;
      }
    }
  }

  & > .directoryBottomContainer {
    // height: calc(100% - 22vh);
    height: 90%;
    display: flex;
    border: 0.5px solid #e5e5e5;

    & > .leftContainer {
      width: 17.5%;
      height: 100%;
      overflow: hidden;
      // border: 1px solid blue;
      display: flex;
      flex-direction: column;
      // gap: 1rem;
      // justify-content: space-between;
      border-right: 0.5px solid #e5e5e5;

      & > .title {
        display: flex;
        justify-content: space-between;
        // gap: 8%;
        padding: 1.5rem 1rem;
        // padding-top: 1.5rem;
        // padding: auto;
        align-items: center;
        height: 10%;

        & > div {
          // padding: 0.5rem 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        & > :nth-child(1) {
          padding: 0.5rem 2.5rem;
          padding-left: 0;
          font-size: 1.6rem;
          font-weight: 800;
        }
        & > :nth-child(2) {
          font-size: 1rem;
          font-weight: 600;
          background: linear-gradient(
            270.43deg,
            #4caf50 3.17%,
            #4caf50 3.18%,
            #93d996 105.2%
          );
          border-radius: 5px;
          color: white;
          height: max-content;
          padding: 0.5rem 2.5rem;
          cursor: pointer;
        }
      }

      & > .searchDiv {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem;
        // height: 12%;

        & > input {
          outline: none;
          border: none;
          padding: 1rem 2rem;
          // margin: 0 3%;
          background: rgba(237, 237, 237, 0.37);
          border-radius: 10px;
          width: 100%;
          color: #5f6163;
          font-size: 1.2rem;
          font-weight: 700;
          transition: all ease-in 0.4s;

          &::placeholder {
            color: rgb(95, 97, 99, 0.5);
          }

          &:hover {
            background: #e5e5e5;
          }
        }
      }

      & > .filterItems {
        // height: 37vh;
        overflow-y: scroll;
        height: 82%;
        margin-top: 1.5rem;
        border-top: 0.5px solid #e5e5e5;

        & > .singleItem {
          display: flex;
          justify-content: space-between;
          padding: 1.5rem 3%;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: 600;
          // border-top: 0.5px solid #e5e5e5;
          border-bottom: 0.5px solid #e5e5e5;
        }
      }
    }

    & > .rightContainer {
      width: 82.5%;
      height: 100%;
      // border: 1px solid green;

      & > .headingDiv {
        width: 100%;
        display: flex;
        height: 7.8%;

        & > div {
          border-right: 0.5px solid #e5e5e5;
          border-bottom: 0.5px solid #e5e5e5;
          flex-grow: 1;
          color: #5f6163;
          font-weight: 800;
          font-size: 1.1rem;
          padding: 1%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 14.4%;
          text-align: center;
        }

        & > :nth-child(2) {
          width: 28%;
        }
      }

      & > .dataTotalContainer {
        height: 84.2%;
        overflow-y: scroll;
      }

      & > .footerDiv {
        height: 8%;
        display: flex;
        justify-content: space-between;
        // border: 1px solid red;

        // height: inherit;'
        & > :nth-child(1) {
          width: 14.4%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          white-space: nowrap;
          font-size: 1.2rem;
          padding: 0 2%;

          & > :nth-child(2) {
            font-weight: 700;
          }

          & > div {
            cursor: pointer;
          }
        }

        & > .checkBox {
          display: flex;
          gap: 1rem;
          align-items: center;
          width: 70%;

          & > :nth-child(1) {
            display: flex;
            gap: 2.5%;
            font-weight: 700;
            font-size: 1rem;
            width: 80%;
            justify-content: flex-end;

            & > div {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              // cursor: pointer;

              & > input {
                width: 1.2rem;
                height: 1.2rem;
                cursor: pointer;
              }

              & > label {
                margin: 0;
                cursor: pointer;
              }
            }
          }

          & > .clearBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            color: white;
            font-size: 1.2rem;
            padding: 0rem 2.5rem;
            height: 65%;
            cursor: pointer;
            background: linear-gradient(
              270.43deg,
              #4caf50 3.17%,
              #4caf50 3.18%,
              #93d996 105.2%
            );
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.dataContainer {
  height: 14.03%;
  min-height: 14.03%;
  border-bottom: 0.5px solid #e5e5e5;
  width: 100%;
  display: flex;

  & > div {
    border-right: 0.5px solid #e5e5e5;
    border-bottom: 0.5px solid #e5e5e5;
    flex-grow: 1;
    color: #5f6163;
    // font-weight: 800;
    font-size: 1rem;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14.4%;
    padding: 0.5rem;
  }
  & > :nth-child(1) {
    display: flex;
    gap: 10px;
    font-size: 1.25rem;
    font-weight: 600;

    & > img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  & > :nth-child(2) {
    width: 28%;
  }

  & > :nth-child(3) {
    flex-direction: column;
    font-weight: 650;
    text-align: center;
  }
  & > :nth-child(4) {
    flex-direction: column;
    font-weight: 650;
    text-align: center;
  }
  & > :nth-child(5) {
    flex-direction: column;
    font-weight: 650;
    text-align: center;
  }
  & > :nth-child(6) {
    font-weight: 650;
    text-align: center;
  }
}
