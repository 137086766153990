.transaction-layout {
  width: 100%;
  flex-grow: 1;
  .side-bar {
    max-width: 22%;
    width: 22%;
    border-right: 0.5px solid $vault-border-color;
    background: white;
    top: 0;
    left: 0;
    transition: all 400ms ease;
    .toolTip {
      display: none;
    }
    .logoutBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-color;
      border-top: 1px solid $border-color;
      height: 65px;
      min-height: 65px;
      font-size: 25px;
      font-weight: bold;
      svg {
        height: 24px;
        margin-right: 6px;
      }
    }
    .profile {
      padding: 60px 50px 0 50px;
      position: relative;
      img {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        margin: auto;
        transform-origin: center;
        transition: all 400ms ease;
        &:hover {
          transform: scale(1.05);
        }
      }
      h5 {
        color: #1b2733;
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 26px;
      }
      .getStartedBtn {
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .currencySelect {
        display: inline-block;
        width: fit-content;
        min-width: 75%;
        cursor: pointer;
        color: #637282;
        border: 1px solid $vault-border-color;
        padding: 8px 10px;
        font-weight: normal;
        line-height: 1;
        margin: 0;
        display: flex;
        align-items: center;
        img {
          background: none;
          height: 16px;
          width: 16px;
          margin-right: 6px;
        }
        h6 {
          font-size: 14px;
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          margin: 0;
        }
      }
      .toggle {
        background: $primary-color;
        color: white;
        z-index: 2;
        height: 28px;
        width: 28px;
        position: absolute;
        top: 72px;
        right: 0;
        margin: auto;
        border-radius: 50%;
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translateX(50%);
        transform-origin: center;
        transition: all 400ms ease;
        &:hover {
          transform: translateX(50%) scale(1.2);
        }
      }
    }

    .menu-scrl {
      margin-top: 3rem;
      .menu-side {
        padding: 0 50px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        justify-content: flex-start;
        .spacer {
          height: 1px;
          min-height: 1px;
          width: 100%;
        }
        .menu-itm {
          margin: 0.9rem 0;
          cursor: pointer;
          text-decoration: none !important;
          opacity: 0.5;
          h5 {
            line-height: 1;
            font-weight: normal;
            color: #637282;
            text-decoration: none;
            margin-bottom: 0.3rem;
            img {
              height: 28px;
              width: auto;
              display: flex;
              align-content: flex-start;
              margin-right: 1.5rem;
              transition: all 400ms ease;
              transform-origin: 0 center;
            }
            span {
              display: none;
            }
          }

          &:hover,
          &.active {
            opacity: 1;
            .menu-itm {
              opacity: 1;
            }
            h5,
            svg {
              font-weight: bold;
              color: #212529;
              img {
                transform: scale(1.1);
              }
            }
          }
        }
        .submenu {
          padding-left: 20px;
        }
      }
    }
    .assetPriceWrapper {
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      .tab-inrest-asset {
        display: flex;
        border: 1px $vault-border-color;
        border-style: solid none none none;
        height: 65px;
        .tab-itm {
          position: relative;
          cursor: pointer;
          width: 0;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px 6px;
          border-bottom: 2px solid $vault-border-color;
          font-size: 14px;
          .backIcon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 30px;
            margin: auto;
            font-size: 18px;
          }
          &.title {
            justify-content: start;
            padding-left: 50px;
            font-size: 20px;
            font-weight: 600;
          }
          &.settings {
            opacity: 1;
            width: 54px;
            min-width: 54px;
            max-width: 54px;
            border-left: 1px solid #e7e7e740;
            border-color: #e7e7e7;
            &.true {
              background: $primary-color;
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
          &.false {
            opacity: 0.25;
          }
          &.true {
            opacity: 1;
            border-color: $primary-color;
          }
        }
      }
      .rate-list-wrapper {
        height: 0 !important;
        flex-grow: 1;
        .rates-list {
          display: flex;
          flex-direction: column;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          overflow-y: auto;
          .coin {
            flex: 0 0 33.33%;
            display: flex;
            padding: 35px 50px;
            border-bottom: 0.5px solid $vault-border-color;
            align-items: center;
            .coin-logo {
              margin: auto;
              margin-left: 0;
              height: 20px;
            }
            .coin-name {
              font-size: 17px;
            }
            .rate {
              margin: auto;
              margin-right: 0;
              text-align: center;
              font-size: 17px;
              small {
                margin-left: 8px;
                font-size: 10px;
                color: #3ea154;
                margin-top: auto;
                svg {
                  margin-left: 4px;
                  height: 11px;
                }
                &.true {
                  color: #da3a3a;
                  svg {
                    transform: scale(-1);
                    path {
                      fill: #da3a3a;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .settings-wrapper {
      .settings-list {
        .settingsMenu {
          cursor: pointer;
          position: relative;
          flex: 0 0 33.33%;
          padding: 0 50px;
          border-bottom: 1px solid $vault-border-color;
          display: flex;
          color: $primary-color;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          justify-content: space-between;
          img {
            height: 20px;
            width: 20px;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 40px;
          }
        }
      }
    }
    .setNewCurency {
      margin-top: 50px;
      border: 1px $vault-border-color;
      border-style: solid none solid none;
      padding: 10px 50px;
      font-weight: bold;
    }
    .defCoinList {
      height: 320px;
      max-height: 320px;
      .coins-list {
        display: flex;
        flex-direction: column;
        .coin {
          flex: 0 0 33.33%;
          position: relative;
          cursor: pointer;
          display: flex;
          padding: 35px 50px;
          border-bottom: 0.5px solid $vault-border-color;
          align-items: center;
          .coin-logo {
            margin: auto;
            margin-left: 0;
            height: 20px;
          }
          .coin-name {
            font-size: 17px;
          }
          .select {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 20px;
            margin: auto;
            height: 25px;
            width: 25px;
          }
        }
      }
    }
    .logout {
      height: 55px;
      background: #8b8b8b;
      padding: 0 15px;
      display: flex;
      align-items: center;
      h5 {
        color: white;
        font-size: 22px;
        line-height: 1;
        font-weight: 600;
        margin: auto;
        text-align: center;
      }
    }
    &.collapse {
      z-index: 3;
      width: 105px;
      max-width: 105px;
      .toolTip {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 85%;
        margin: auto;
        height: 30px;
        width: 140px;
        background: white;
        border: 1px solid $vault-border-color;
        font-size: 17px;
        justify-content: center;
        align-items: center;
        color: $primary-color;
        z-index: 10;
        font-size: 12px;
        font-weight: 600;
      }
      .profile {
        padding: 0;
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 50px;
          height: 50px;
          border-radius: 25px;
          margin: auto;
        }
        h5,
        .getStartedBtn,
        .col {
          display: none;
        }
        &:hover {
          .toolTip {
            display: none;
          }
        }
      }
      .menu-scrl {
        margin: 0;
        margin-top: 32px;
        .menu-side {
          position: relative;
          padding: 0 5px;
          .menu-itm {
            margin: 8px 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            h5 {
              img {
                height: auto;
                width: 30px;
                opacity: 0.25;
                margin: 0;
                transform-origin: center;
              }
              span {
                display: none;
              }
            }
            &:hover,
            &.active {
              opacity: 1;
              h5 {
                img {
                  transform: scale(1.2);
                  opacity: 1;
                }
              }
            }
            &:hover {
              .toolTip {
                display: none;
              }
            }
          }
        }
      }
      .assetPriceWrapper {
        flex: unset;
        display: flex;
        flex-direction: column;
        .tab-inrest-asset {
          display: flex;
          flex-direction: column;
          height: unset;
          .tab-itm {
            display: none;
          }
        }
        .rate-list-wrapper {
          display: none;
        }
      }
      .logoutBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 6px;
        color: $primary-color;
        svg {
          height: 30px;
          width: 30px;
        }
        span {
          display: none;
        }
      }
    }
    &.admin {
      .userFind {
        flex: 1;
        padding: 30px;
        display: flex;
        flex-direction: column;
        .searchWrap {
          height: 60px;
          width: 100%;
          position: relative;
          border: 1px solid $vault-border-color;
          display: flex;
          .searchInp {
            width: 0;
            flex: 1;
            background: none;
            border: none;
            padding: 0 30px;
          }
          .box {
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 80%;
              width: 80%;
            }
          }
        }
        .userList {
          margin: 30px 0;
          > div {
            padding-right: 1px;
            .viewAsUserItem {
              height: 90px;
              padding: 0 20px;
              border: 1px solid $border-color;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              transition: all 400ms ease;
              cursor: pointer;
              > div,
              img {
                transition: all 400ms ease;
              }
              .profileImg {
                height: 60px;
                width: 60px;
                border-radius: 30px;
                margin-right: 15px;
              }
              .nameEmail {
                .name {
                  font-size: 17px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                }
                .email {
                  font-size: 13px;
                  display: flex;
                  align-items: center;
                }
                svg {
                  margin-left: 5px;
                }
              }
              &:hover {
                background: whitesmoke;
                > div,
                img {
                  transform: scale(1.05);
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-height: 1050px) {
    zoom: 0.95;
  }
  @include xxxl {
    zoom: 0.8;
  }
  @include xxl {
    zoom: 0.7;
  }
  @include xl {
    zoom: 0.6;
  }
  @include lg {
    zoom: 0.5;
  }
  @include mdlscape {
    zoom: 0.35;
  }
  @include smlscape {
    zoom: 0.25;
  }
  &.true {
    @media (max-height: 1050px) {
      zoom: 0.85;
    }
    @include xxxl {
      zoom: 0.7;
    }
    @include xxl {
      zoom: 0.6;
    }
    @include xl {
      zoom: 0.5;
    }
    @include lg {
      zoom: 0.4;
    }
    @include mdlscape {
      zoom: 0.28;
    }
    @include smlscape {
      zoom: 0.2;
    }
  }
}
