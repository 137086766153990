.landingContainer {
  height: 100%;
  // border: 1px solid red;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  border-bottom: 0.5px solid #e5e5e5;

  & > .leftContainer {
    width: 60%;
    padding-left: 6.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.5rem;

    & > .title {
      font-size: 4.2rem;
      line-height: 110%;
      font-weight: 800;
      width: 100%;

      & > :nth-child(1) {
        font-size: 5.6rem;
        height: 7rem;
        // border: 1px solid red;
        overflow: hidden;
        position: relative;

        & > div {
          position: absolute;
          background: linear-gradient(89.97deg, #4caf50 1.23%, #93d996 97.81%);
          color: transparent;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
          // transform: 0.8s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
          // animation: titleChange 7s linear infinite;
          // animation-direction: reverse;
          // inset: 0;
          overflow: hidden; /* Ensures the content is not revealed until the animation */
          border-right: 0.15em solid #4caf50; /* The typwriter cursor */
          white-space: nowrap; /* Keeps the content on a single line */
          margin: 0 auto; /* Gives that scrolling effect as the typing happens */
          // letter-spacing: 0.15em; /* Adjust as needed */
          animation: typing 3.5s steps(40, end),
            blink-caret 0.75s step-end infinite;
        }

        // & > :nth-child(2) {
        //   animation-delay: 2.2s;
        //   visibility: hidden;
        // }

        // & > :nth-child(3) {
        //   animation-delay: 4.4s;
        // }

        // @keyframes titleChange {
        //   0% {
        //     transform: translateY(0);
        //     visibility: visible;
        //   }
        //   20% {
        //     transform: translateY(0);
        //     visibility: visible;
        //   }
        //   40% {
        //     transform: translateY(-150%);
        //     visibility: hidden;
        //   }
        //   100% {
        //     transform: translateY(150%);
        //     visibility: hidden;
        //   }
        // }

        & > :nth-child(2) {
          display: none;
        }

        & > :nth-child(3) {
          display: none;
        }
        /* The typing effect */
        @keyframes typing {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }

        /* The typewriter cursor effect */
        @keyframes blink-caret {
          from,
          to {
            border-color: transparent;
          }
          50% {
            border-color: #4caf50;
          }
        }
      }

      & > :nth-child(2) {
        color: #5f6163;
        white-space: nowrap;
      }
    }

    & > .para {
      font-size: 1.75rem;
      width: 80%;
      color: #5f6163;
      font-weight: 400;
      line-height: 192%;
    }

    & > .btnDiv {
      display: flex;
      width: 60%;
      max-width: 600px;
      gap: 1rem;
 border-radius: 35px;
      & > div {
        // width: 50%;
        padding: 1.2rem 5rem;
        font-size: 1.6rem;
        font-weight: 700;
        cursor: pointer;
        white-space: nowrap;
        transition: all ease-in 0.4s;

        &:hover {
          scale: 1.025;
          border: 1px solid currentColor;
        }
      }

      & > :nth-child(1) {
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 35px;
        color: #5f6163;
      }

      & > :nth-child(2) {
        background: linear-gradient(
          270.43deg,
          #4caf50 3.17%,
          #4caf50 3.18%,
          #93d996 105.2%
        );
        border-radius: 35px;
        color: white;
      }
    }
  }

  & > .rightContainer {
    width: 40%;
    display: flex;
    gap: 1rem;
    // justify-content: flex-end;
    // align-items: flex-end;
    // border: 1px solid red;

    & > img {
      // height: 100%;
      max-width: 100%;
      object-fit: contain;
      padding-right: 5vw;
      max-height: 100%;
      padding-top: 2rem;
    }
  }
}

.fullCardsContainer {
  width: 65%;
  min-width: 65%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  position: relative;
  // border: 1px solid blue;

  & > div {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
    min-height: 100%;
    // transform: 0.8s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    animation: containerChange 8.75s linear infinite;
    animation-direction: reverse;

    & > :nth-child(1) {
      background: linear-gradient(
        180deg,
        rgba(76, 175, 80, 0.1) 0%,
        rgba(147, 217, 150, 0.1) 100%
      );

      & > :last-child {
        & > div {
          background: linear-gradient(
            270.43deg,
            #4caf50 3.17%,
            #4caf50 3.18%,
            #93d996 105.2%
          );
          color: white;
        }
      }
    }

    @keyframes containerChange {
      0% {
        transform: translateY(0);
        visibility: visible;
      }
      20% {
        transform: translateY(0);
        visibility: visible;
      }
      30% {
        transform: translateY(150%);
        visibility: hidden;
      }
      85% {
        transform: translateY(-150%);
        visibility: hidden;
      }
    }
  }

  & > :nth-child(2) {
    animation-delay: 2.75s;
    visibility: hidden;
  }

  & > :nth-child(3) {
    animation-delay: 5.5s;
  }
}

.halfCardsContainer {
  width: 65%;
  min-width: 65%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  // justify-content: center;
  overflow: hidden;
  position: relative;
  // border: 1px solid green;

  & > div {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    height: 100%;
    min-height: 100%;
    animation: containerChange 7s linear infinite;
    animation-direction: reverse;

    @keyframes containerChange {
      0% {
        transform: translateY(0);
        visibility: visible;
      }
      20% {
        transform: translateY(0);
        visibility: visible;
      }
      30% {
        transform: translateY(150%);
        visibility: hidden;
      }
      85% {
        transform: translateY(-150%);
        visibility: hidden;
      }
    }
  }

  & > :nth-child(2) {
    animation-delay: 2.2s;
    visibility: hidden;
  }

  & > :nth-child(3) {
    animation-delay: 4.4s;
  }
}

.singleCards {
  width: 100%;
  height: 53%;
  min-height: 53%;
  // border: 1px solid palevioletred;
  padding: 5%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 6%;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  transition: all ease-in 0.4s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  & > .imageDiv {
    width: 7.5vh;
    height: 7.5vh;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 60%;
      object-fit: contain;
    }
  }

  & > .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #5f6163;
  }

  & > .para {
    font-size: 1rem;
  }

  & > .details {
    display: flex;
    justify-content: space-between;

    & > div {
      & > .key {
        font-size: 1rem;
      }

      & > .value {
        font-size: 1.5rem;
        font-weight: 650;
      }
    }

    & > :nth-child(1) {
      & > .value {
        color: #4caf50;
      }
    }
  }

  & > .cardsBtn {
    display: flex;
    justify-content: space-between;

    & > div {
      // background: linear-gradient(
      //   270.43deg,
      //   #4caf50 3.17%,
      //   #4caf50 3.18%,
      //   #93d996 105.2%
      // );
      color: #5f6163;
      background-color: white;
      border: 0.5px solid #e5e5e5;
      border-radius: 35px;
      // color: white;
      padding: 2.5% 4%;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
